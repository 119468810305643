import React from 'react';
import get from 'lodash.get';
import InputFieldWidget from '../components/InputFieldWidget';
import InputFieldStringWidget from '../components/InputFieldStringWidget';
import InputToggleWidget from '../components/InputToggleWidget';
import InputRadioGroupWidget from '../components/InputRadioGroupWidget';
import InputDateWidget from '../components/InputDateWidget';

class Question extends React.Component {
    getQuestionComponent (type) {
		switch (type) {
			case 'number':
				return { Component : InputFieldWidget, mainValueProperty: 'number' };
			case 'string':
				return { Component: InputFieldStringWidget, mainValueProperty: 'string' };
			case 'bool':
                return { Component: InputToggleWidget, mainValueProperty: 'boolean' };
            case 'date':
                return { Component: InputDateWidget, mainValueProperty: 'date' };
            case 'enumString':
                return { Component: InputRadioGroupWidget, mainValueProperty: 'enumString' };
			default:
				throw new Error(`Unknown type '${type}'`);
		}
    }

    render () {
        const {
            field,
            values,
            validationResult,
            extractValue,
            getValidationMessage,
            ...props
        } = this.props;

        const error = getValidationMessage(validationResult, field.id);
        const { Component, mainValueProperty } = this.getQuestionComponent(field.dataType);
        const value = get(values, `${field.supply}.value`);
        const defaultValue = get(value, mainValueProperty);
        return (
            <Component
                key={field.id}
                value={value}
                values={values}
                defaultValue={defaultValue}
                error={error}
                {...field}
                {...props}
	        />
        );
    }
}

export default Question;
