import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { confirmERPTicket } from 'http/hubspot';
import { getResourceTemplate, getResources } from 'http/productEngine';
import { getEntriesInPeriodByAccountNumber } from 'http/erpBroker';
import erpSystems, { erpIdentifiers } from 'util/erpSystems';
import { sendAccounplantApprovalEmail } from 'http/email';
import { getAccountPlansFact } from 'util/FactMapUtil';
import useUser from 'util/useUser';

import { OVERSKRIFT } from './accountTypes';
import { formatAccounts } from './formatAccounts';
import PublishmentsViewer from './PublishmentsViewer';
import ApprovalModule from './ApprovalModule';
import AccountPlanTable from './AccountPlanTable';
import PostEntriesBooker from './PostEntriesBooker';

const groupHasTypeHomogeneity = (accountplan, accountsNumbersToCheck) => {
    const accountNumberToType = accountplan.reduce((acc, { number, accountType }) => {
        return { ...acc, [number]: accountType };
    }, {});

    const [firstAccount, ...restOfTheAccounts] = accountsNumbersToCheck;

    const firstAccountType = accountNumberToType[firstAccount];

    return restOfTheAccounts.every(accountNumber => {
        return accountNumberToType[accountNumber] === firstAccountType;
    });
};

const getPeriodDatesAsISO = ({ start, end }) => {
    const [from, to] = [start, end].map(({ year, month, day }) => {
        return [year, month, day].map(part => {
            return part.toString().padStart(2, '0');
        }).join('-');
    });

    return { from, to };
};

const ClosingSheet = ({ values, productMetadata, pagePrefix, currentPeriod, onChange: triggerFactUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [filterQuery, setFilterQuery] = useState('');
    const [showZeroAccounts, setShowZeroAccounts] = useState(false);
    const [showOnlyErrorLines, setShowOnlyErrorLines] = useState(false);
    const [showOnlyUncheckedLines, setShowOnlyUncheckedLines] = useState(false);
    const [selectedAccounts, setSelectedAccounts] = useState(new Set());
    const [resourceData, setResourceData] = useState(null);
    const user = useUser();

    const toggleAccountSelection = accountNumber => {
        const selectionCopy = new Set(selectedAccounts);
        if (selectionCopy.has(accountNumber)) {
            selectionCopy.delete(accountNumber);
        } else {
            selectionCopy.add(accountNumber);
        }
        setSelectedAccounts(selectionCopy);
    };

    const accountplansFact = useMemo(() => {
        return getAccountPlansFact(values) || {};
    }, [values]);

    const questionGroups = useMemo(() => {
        if (!accountplansFact.id) return [];
        if (!productMetadata) return [];

        const factMetadata = productMetadata.facts[accountplansFact.id];
        if (!factMetadata) return [];

        const { closingSheetQuestionGroupsJSON } = factMetadata.options;
        if (!closingSheetQuestionGroupsJSON) return [];

        const parsedQuestionGroups = JSON.parse(closingSheetQuestionGroupsJSON);
        return parsedQuestionGroups;
    }, [accountplansFact.id, productMetadata]);
    
    useEffect(() => {
        if (!accountplansFact.id) return;
        if (!productMetadata) return;

        const factMetadata = productMetadata.facts[accountplansFact.id];
        if (!factMetadata) return;

        const { resourceConfigurationJSON } = factMetadata.options;
        if (!resourceConfigurationJSON) return;

        const { resourceTemplateSlug, mappableXbrlTags } = JSON.parse(resourceConfigurationJSON);
        
        const fetchData = async () => {
            const [resources, resourceTemplate] = await Promise.all([
                getResources(resourceTemplateSlug),
                getResourceTemplate(resourceTemplateSlug),
            ]);

            const formattedResources = resources.map(({ values, slug }) => {
                return {
                    label: values[resourceTemplate.titleProperty],
                    slug,
                };
            })

            setResourceData({
                resources: formattedResources,
                template: resourceTemplate,
                mappableXbrlTags: new Set(mappableXbrlTags),
            });
        };

        setLoading(true);
        fetchData().then(() => setLoading(false));
    }, [accountplansFact.id, productMetadata]); 

    const [modelXbrlTags, modelAccountantCodes] = useMemo(() => {
        const { requiredXbrlTags, requiredAccountantCodes } = productMetadata;

        return [
            new Set(requiredXbrlTags?.filter(x => x)),
            new Set(requiredAccountantCodes?.filter(x => x)),
        ];
    }, [productMetadata]);

    const [accountplan, actionsRequired] = useMemo(() => {
        const factValue = accountplansFact.value || {};

        const accounts = factValue.accounts || [];
        const lastyear = factValue.lastyear || [];
        const checkedAccounts = factValue.checkedAccounts || {};
        const draftPostEntries = factValue.draftPostEntries || [];
        const childToMasterAccountTable = factValue.childToMasterAccountTable || {};
        const accountToResourceTable = factValue.accountToResourceTable || {};
        const questionAnswers = factValue.questionAnswers || {};

        const resourceMappableXbrlTags = resourceData?.mappableXbrlTags;

        const shouldIncludeArchivedPostEntries  = user.erp === erpIdentifiers.manual;
        const archivedPostEntries = (
            shouldIncludeArchivedPostEntries
                ? factValue.archivedPostEntries || []
                : []
        );

        const formattedAccounts = formatAccounts({
            currentYear: accounts,
            lastYear: lastyear,
            modelXbrlTags,
            modelAccountantCodes,
            checkedAccounts,
            draftPostEntries,
            archivedPostEntries,
            childToMasterAccountTable,
            accountToResourceTable,
            resourceMappableXbrlTags,
            questionGroups,
            questionAnswers,
        });

        const questionIdsToIgnore = new Set();

        let totalActionsRequired = 0;

        for (const account of formattedAccounts) {
            account.actionsRequiredCount = 0;

            account.actionsRequiredCount += Number(account.accountantCodeMapping.hasWarning);
            account.actionsRequiredCount += Number(account.xbrlMapping.hasWarning);

            account.actionsRequiredCount += Number(account.accountantCodeMapping.hasCriticalError);
            account.actionsRequiredCount += Number(account.xbrlMapping.hasCriticalError);

            account.actionsRequiredCount += Number(account.resourceMappingMissing);

            for (const { id, answerValue } of account.questionsAndAnswers) {
                if (questionIdsToIgnore.has(id)) continue;

                if (!answerValue) {
                    account.actionsRequiredCount++;
                }

                questionIdsToIgnore.add(id); // multiple accounts can have the same question(s), so only count it once
            }

            totalActionsRequired += account.actionsRequiredCount;
        }

        return [formattedAccounts, totalActionsRequired];
    }, [accountplansFact, modelXbrlTags, modelAccountantCodes, resourceData, user.erp, questionGroups]);

    const filteredAcccounts = useMemo(() => {
        let accountsToShow = accountplan;

        // reset account selection on filters change
        setSelectedAccounts(new Set());

        const alwaysInclude = ({ accountType, masterAccountNumber }) => {
            if (accountType === OVERSKRIFT) return true;
            if (masterAccountNumber) return true;

            return false;
        };

        if (!showZeroAccounts) {
            accountsToShow = accountsToShow.filter(acc => {
                if (alwaysInclude(acc)) return true;

                const { groupTotal } = acc;

                const currentYearAmount = groupTotal?.currentYear ?? Number(acc.currentYearAmount);
                const lastYearAmount = groupTotal?.lastYear ?? Number(acc.lastYearAmount);

                const amountIsZeroBothYears = (
                    currentYearAmount === 0 &&
                    lastYearAmount === 0
                );

                return amountIsZeroBothYears === false;
            });
        }

        if (showOnlyErrorLines) {
            accountsToShow = accountsToShow.filter(acc => {
                if (alwaysInclude(acc)) return true;

                return acc.actionsRequiredCount > 0;
            });
        }

        if (showOnlyUncheckedLines) {
            accountsToShow = accountsToShow.filter(acc => {
                if (alwaysInclude(acc)) return true;

                return acc.isChecked === false;
            });
        }

        if (filterQuery) {
            accountsToShow = accountsToShow.filter(acc => {
                if (alwaysInclude(acc)) return true;

                return acc.text.toLowerCase().includes(filterQuery.toLowerCase());
            });
        }

        // hide child accounts of hidden master accounts
        const visibleMasterAccounts = new Set();
        accountsToShow.forEach(account => {
            if (account.childAccounts) visibleMasterAccounts.add(account.number);
        });

        accountsToShow = accountsToShow.filter(({ masterAccountNumber }) => {
            if (!masterAccountNumber) return true;
            return visibleMasterAccounts.has(masterAccountNumber);
        });

        return accountsToShow;
    }, [accountplan, showZeroAccounts, showOnlyErrorLines, showOnlyUncheckedLines, filterQuery]);

    const runERPAction = (actionData = {}) => {
        const productFact = productMetadata.facts[accountplansFact.id];
        const triggerFactID = productFact.options.trigger;

        return triggerFactUpdate(triggerFactID, { number: Date.now() }, actionData);
    };

    const updateMappings = accountsToPatch => {
        if (!groupHasTypeHomogeneity(accountplan, Object.keys(accountsToPatch))) {
            return toast.error('Det er ikke tilladt at klassificere konti på tværs af kontotype');
        }

        return runERPAction({ accountsToPatch });
    };

    const handleApproval = async note => {
        const { stakeholderEmail } = accountplansFact.value;

        // make sure the account plan is approved by a different person
        // than the one initiating the mapping request
        const shouldSendMail = user?.login?.loginEmail !== stakeholderEmail;

        await Promise.all([
            runERPAction({ doApproveAccountplan: true }),
            confirmERPTicket(productMetadata.id, productMetadata.year),
            shouldSendMail && sendAccounplantApprovalEmail({
                productLink: pagePrefix + '/index',
                note: note,
                email: stakeholderEmail,
                displayName: user.getDisplayName(),
            }),
        ]);
    };

    const userErpSystem = erpSystems[user.erp];
    const getEntriesByAccountNumber = useCallback(number => {
        if (!userErpSystem?.supportsClosingSheet) {
            return Promise.resolve([]);
        }

        const { from, to } = getPeriodDatesAsISO(currentPeriod);
        return getEntriesInPeriodByAccountNumber(number, from, to);
    }, [currentPeriod, userErpSystem]);

    const draftPostEntriesAndRefetchAccountPlan = (specificEntryNumbersToArchive = undefined) => {
        const contextArgs = {
            shouldArchiveDraftPostEntries: true,
        };

        // if specificEntryNumbersToArchive is not provided, all post entries should be drafted
        if (specificEntryNumbersToArchive) {
            contextArgs.specificEntryNumbersToArchive = specificEntryNumbersToArchive
        }

        return runERPAction(contextArgs)
    };

    const addPostEntry = postEntryToAdd => {
        return runERPAction({
            postEntryToAdd,
        });
    };

    const groupAccounts = (masterAccount, childAccounts = []) => {
        if (!groupHasTypeHomogeneity(accountplan, [masterAccount, ...childAccounts])) {
            return toast.error('Det er ikke tilladt at gruppere konti på tværs af kontotype');
        }

        return runERPAction({
            accountsToGroup: {
                masterAccount,
                childAccounts,
            },
        });
    };

    const renameGroup = (masterAccount, newName) => {
        return runERPAction({
            groupToRename: {
                masterAccount,
                newName,
            },
        });
    };

    const disbandAccountGroup = groupToDisband => {
        return runERPAction({
            groupToDisband,
        });
    };

    const updateAccountInfo = (accountNumber, newData) => {
        return runERPAction({
            accountToUpdate: {
                accountNumber,
                newData,
            },
        });
    };

    const removeAccountFromGroup = (accountNumberToRemoveFromGroup) => {
        return runERPAction({ accountNumberToRemoveFromGroup });
    };

    const deletePostEntriesByNumber = entryNumberToDelete => {
        return runERPAction({ entryNumberToDelete });
    };

    const toggleAccountChecked = accountNumberToToggle => {
        return runERPAction({ accountNumberToToggle });
    };

    const setAccountResourceMapping = (accountNumber, resourceSlug) => {
        return runERPAction({
            accountResourceToSet: {
                accountNumber,
                resourceSlug,
            },
        });
    };

    const provideQuestionAnswer = (questionID, questionAnswerValue) => {
        return runERPAction({
            questionAnswer: {
                questionID,
                questionAnswerValue,
            },
        })
    };

    if (loading) {
        return (
            <Loader
                inline='centered'
                size='huge'
                active
            />
        );
    }

    const postEntries = accountplansFact.value?.draftPostEntries || [];
    const archivedPostEntries = accountplansFact.value?.archivedPostEntries || [];
    const accountInfoTable = accountplansFact.value?.accountInfoTable || {};
    const masterAccountNameOverrides = accountplansFact.value?.masterAccountNameOverrides || {};

    let resourceInfo;
    if (resourceData) {
        const accountToResourceTable = accountplansFact.value?.accountToResourceTable || {};

        resourceInfo = {
            ...resourceData,
            accountToResourceTable,
            setAccountResourceMapping,
        };
    }

    const renderTable = () => {
        return (
            <AccountPlanTable
                setFilterQuery={setFilterQuery}
                showZeroAccounts={showZeroAccounts}
                setShowZeroAccounts={setShowZeroAccounts}
                showOnlyErrorLines={showOnlyErrorLines}
                setShowOnlyErrorLines={setShowOnlyErrorLines}
                showOnlyUncheckedLines={showOnlyUncheckedLines}
                setShowOnlyUncheckedLines={setShowOnlyUncheckedLines}
                actionsRequired={actionsRequired}
                filteredAcccounts={filteredAcccounts}
                selectedAccounts={selectedAccounts}
                setSelectedAccounts={setSelectedAccounts}
                toggleAccountSelection={toggleAccountSelection}
                updateMappings={updateMappings}
                groupAccounts={groupAccounts}
                renameGroup={renameGroup}
                disbandAccountGroup={disbandAccountGroup}
                modelXbrlTags={modelXbrlTags}
                modelAccountantCodes={modelAccountantCodes}
                accountplan={accountplan}
                lastYearEditable={accountplansFact.value?.lastYearEditable}
                getEntriesByAccountNumber={getEntriesByAccountNumber}
                addPostEntry={addPostEntry}
                accountInfoTable={accountInfoTable}
                updateAccountInfo={updateAccountInfo}
                fiscalPeriod={currentPeriod}
                removeAccountFromGroup={removeAccountFromGroup}
                deletePostEntriesByNumber={deletePostEntriesByNumber}
                toggleAccountChecked={toggleAccountChecked}
                masterAccountNameOverrides={masterAccountNameOverrides}
                resourceInfo={resourceInfo}
                provideQuestionAnswer={provideQuestionAnswer}
                taxYear={Number(productMetadata.year)}
            />
        );
    };

    const renderApprovalModule = () => {
        const { needsRevision, revised } = accountplansFact?.value || {};

        if (revised) return null;
        if (!needsRevision) return null;
        
        return <ApprovalModule onApproval={handleApproval} />;
    };

    const renderBookPostEntriesModule = () => {
        const fiscalPeriod = getPeriodDatesAsISO(currentPeriod);

        return (
            <PostEntriesBooker
                postEntries={postEntries}
                archivedPostEntries={archivedPostEntries}
                fiscalPeriod={fiscalPeriod}
                draftPostEntriesAndRefetchAccountPlan={draftPostEntriesAndRefetchAccountPlan}
            />
        );
    };

    return (
        <>
            {<PublishmentsViewer />}
            {renderTable()}
            {renderApprovalModule()}
            {renderBookPostEntriesModule()}
        </>
    );
};

export default ClosingSheet;
