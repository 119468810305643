import React, { useState } from 'react';
import {
    Popup,
    Icon,
    Confirm,
    Button,
    Message,
    Loader,
	Modal,
} from 'semantic-ui-react';

import { toast } from 'react-toastify';
import * as payment from 'http/payment.js';
import { getSubscriptionsOverview } from 'http/payment';
import { formatDate } from 'util/format/DateTime';
import { formatNumber } from 'util/format/Number';
import { withRootUserData } from 'util/withUserData';
import getProducts from 'util/getProducts';
import ColoredText from 'design/atoms/ColoredText';
import { useBreakpoints } from 'design/atoms/Media';

import ProfileTable from './ProfileTable';
import SubscriptionPackageSelector from 'design/atoms/SubscriptionPackageSelector/SubscriptionPackageSelector';

const SubscriptionMenu = ({ subscription, products, onUnsubscribe, onChangeTier }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [isCanceling, setIsCanceling] = useState(false);
	const [isChangingPackage, setIsChangingPackage] = useState(false);
	const [selectedTier, setSelectedTier] = useState(subscription.selectedPackageTier);
	const breakpoints = useBreakpoints();

	const associatedProduct = products.find(product => product.id === subscription.productID);

	const renderUnsubscribeConfirm = () => {
		if (!isCanceling) {
			return null;
		}

		const { productName } = subscription;
		const content = `Er du sikker på at du vil opsige dit abonnement for ${productName}?`;

		const confirm = <Button basic>Opsig</Button>;
		const cancel  = <Button color='black'>Annuller</Button>;

		return (
			<Confirm
				open
				header='Afmeld'
				confirmButton={confirm}
				cancelButton={cancel}
				content={content}
				onConfirm={() => {
					setIsCanceling(false);
					onUnsubscribe();
				}}
				onCancel={() => setIsCanceling(false)}
			/>
		);
	};

	const renderChangePackageModal = () => {
		if (!isChangingPackage) {
			return null;
		}

		const choiceIsValid = selectedTier !== subscription.selectedPackageTier;

		const { tiers } = associatedProduct.subscriptionPackageSet;

		const selectedTierIdx = tiers.findIndex(tier => tier.id === selectedTier);
		const subscriptionTierIdx = tiers.findIndex(tier => tier.id === subscription.selectedPackageTier);
		const isUpgrade = selectedTierIdx > subscriptionTierIdx;

		return (
			<Modal
				open
				closeIcon
				onClose={() => setIsChangingPackage(false)}
				size='large'
			>
				<Modal.Header><Icon name='box' /> Skift pakke</Modal.Header>
				<Modal.Content>
					<div style={{ marginBottom: '1em' }}>
						<SubscriptionPackageSelector
							onPackageSelected={(_, id) => setSelectedTier(id)}
							packageSet={associatedProduct.subscriptionPackageSet}
							chosenPackage={selectedTier}
							isChangingPackage={true}
							vat={true}
						/>
					</div>
				</Modal.Content>
				<Modal.Actions>
					<Button 
						floated='left'
						content='Afmeld abonnement'
						icon='x'
						onClick={() => setIsCanceling(true)}
					/>
					<Button
						primary
						content={isUpgrade ? 'Bekræft opgradering' : 'Bekræft skift'}
						icon={isUpgrade ? 'level up' : 'exchange'}
						disabled={!choiceIsValid}
						onClick={() => {
							setIsChangingPackage(false);
							onChangeTier(selectedTier);
						}}
					/>
				</Modal.Actions>
			</Modal>
		);
	};

	const productHasPackageTiers = !!associatedProduct.subscriptionPackageSet;

	const menuItems = (
		<>
			{productHasPackageTiers && (
				<div>
					<ColoredText
						link 
						content='Skift pakke'
						icon='box'
						iconPosition='left'
						underlined={false}
						onClick={() => {
							setMenuOpen(false);
							setIsChangingPackage(true);
						}}
					/>
				</div>
			)}
			
			{!productHasPackageTiers && (
				<div>
					<ColoredText
						link 
						content='Afmeld'
						icon='x'
						iconPosition='left'
						underlined={false}
						onClick={() => {
							setMenuOpen(false);
							setIsCanceling(true);
						}}
					/>
				</div>
			)}
		</>
	);

	const menuPopup = (
		<Popup
			basic
			on='click'
			open={menuOpen}
			onOpen={() => setMenuOpen(true)}
			onClose={() => setMenuOpen(false)}
			trigger={<Icon name='ellipsis horizontal' link />}
			content={menuItems}
			position={breakpoints.select({
				'eq mobile': 'top right',
				'default': 'top center',
			})}
		/>
	);

	return (
		<>
			{menuPopup}
			{renderUnsubscribeConfirm()}
			{renderChangePackageModal()}
		</>
	);
};

class Subscriptions extends React.Component {
    state = {
		cardInfoModalActive: false,
		working: true,
		selectedSubscription: null,
        subscriptions: [],
		products: [],
	};

    componentDidMount = async () => {
        await this.doFetchSubscriptions();
		const products = await getProducts();
        this.setState({
            working: false,
			products,
        });
    };

    doFetchSubscriptions = async () => {
        const subscriptions = await getSubscriptionsOverview();
        this.setState({
            subscriptions,
        });
    };

	unsubscribe = async productID => {
		this.setState({ working: true });

		try {
			await payment.cancelSubscription(productID);
			await this.doFetchSubscriptions();
		} catch (e) {
			toast.error('Kunne ikke afmelde abonnementet. Prøv igen eller kontakt supporten');
		}
		
		this.setState({ working: false });
	};
	
	changePackageTier = async (productID, newTier) => {
		this.setState({ working: true });
		
		try {
			await payment.updateSubscriptionPackageTier(productID, newTier);
			await this.doFetchSubscriptions();
			toast.success('Abonnementspakken blev skiftet');
		} catch {
			toast.error('Kunne ikke skifte abonnementspakke');
		}

		this.setState({ working: false });
	};

	getSubscriptionRows = () => {
		const { subscriptions } = this.state;

		return subscriptions.map(sub => {
			const {
				productID,
				productName,
				productIcon,
				price,
				nextRenewal,
				nextTaxYear,
			} = sub;

			const basePrice = formatNumber(price * 1.25);
			const subPeriodEnd = formatDate(nextRenewal);

			const productNameJSX = <>
				<Icon name={productIcon} color='green' />
				{productName}
			</>;

			const basePriceJSX = (
				<Popup
					trigger={<span>{basePrice},-</span>}
					position='top center'
					content='Pris inkl. moms'
				/>
			);

			return {
				key: productID,
				cells: [
					productNameJSX,
					basePriceJSX,
					subPeriodEnd,
					nextTaxYear,
				],
			};
		});
	};

	renderMissingPaymentMessage = () => {
		const { subscriptions } = this.state;

		const anyUnpaid = subscriptions.some(sub => !sub.paid);

		if (!anyUnpaid) {
			return null;
		}

		return (
			<Message
				header='Betaling mangler'
				content='Ved opdatering af kortoplysninger forsøger vi automatisk at trække penge for abonnementer med manglende betaling.'
				color='yellow'
				icon='info circle'
			/>
		);
	};

    render = () => {
        const { working, subscriptions } = this.state;

        if (working) {
            return <Loader inline='centered' active />;
        }

		if (subscriptions.length === 0) {
			return <div>
                Du har ingen aktive abonnementer på Digital Revisor
            </div>;
        }

		return (
			<div>
				{this.renderMissingPaymentMessage()}
				<ProfileTable
					header={[
						'Pakke',
						'Pris',
						'Fornyes d.',
						'Næste skatteår',
					]}
					body={this.getSubscriptionRows()}
					actions={[
						subIdx => {
							const subscription = this.state.subscriptions[subIdx];
							return (
								<SubscriptionMenu
									products={this.state.products}
									subscription={subscription}
									onUnsubscribe={() => this.unsubscribe(subscription.productID)}
									onChangeTier={tier => this.changePackageTier(subscription.productID, tier)}
								/>
							);
						},
					]}
				/>
			</div>
		);

    }
}

export default withRootUserData(Subscriptions);
