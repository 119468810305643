/* eslint-disable no-throw-literal */
import { setRefreshToken, clearAllTokens, setChosenUser } from 'util/token';
import { removeImpersonationID } from 'util/impersonation';
import { refreshProducts } from 'util/getProducts';
import browserHistory from 'util/browserHistory';
import cvrHasErrors from 'util/cvrValidator';
import * as tawkTo from 'util/tawkTo';
import getEmailRegexp from 'util/getEmailRegexp';
import fetchAccessToken from 'network/fetch/fetchAccessToken';
import { POST } from 'network/fetch';

class AuthHelper {
	static async authenticate(userData, history, location, onLoginCallback) {
		let errors = this.hasErrors(userData);
		if (errors) {
			throw errors;
		}

		const resp = await POST('token', {
			email: userData.email,
			password: userData.password,
		});

		if (!resp.wasSuccessful()) {
			throw { other: 'Email eller kodeord er forkert' };
		}

		const { token, chosenUser } = resp.getData();

		// save the refresh token in local storage
		setRefreshToken(token);

		// save the id of the user we wish to produce access tokens for
		setChosenUser(chosenUser);
			
		// fetch the access token using the
		// refresh token & the chosen user we just set
		try {
			await fetchAccessToken();
			await refreshProducts();
		} catch (e) {
			throw new Error(e);
		}

		if (onLoginCallback instanceof Function) {
			await onLoginCallback();
		}

		history.push(location || '/');
	}

	static deAuthenticate(redirect) {
		clearAllTokens();
		removeImpersonationID();
		tawkTo.shutdownWidget();

		// optionally save redirect in URL
		let redirectURL = '';
		if (redirect) {
			redirectURL = `?redirect=${encodeURIComponent(redirect)}`;
		}

		browserHistory.push('/login' + redirectURL);
	}

	static async sendRegisterRequest(userData) {
		const response = await POST('register', userData);
		return { data: response };
	}

	static async register(userData, history, location, timeoutDuration) {
		const { data } = await this.sendRegisterRequest(userData);

		if (!data.success) {
			throw { other: data.message };
		}

		setTimeout(async () => {
			try {
				await this.authenticate(userData, history, location);
			} catch (errors) {
				throw errors;
			}
		}, timeoutDuration);
	}

	static async resetPassword(userData, resetToken) {
		let errors = this.hasErrors(userData);
		if(errors) throw errors;
		try {
			const data = await this.sendReset(userData, resetToken);
			return data;
		} catch (error) {
			throw error;
		}
	}

	static async sendReset(userData, resetToken) {
		const data = await POST('reset/execute', {
			...userData,
			resetToken,
		});

		if (data.success) {
			return { success: true };
		} else {
			throw { other: 'Kodeord kunne ikke nulstilles' };
		}
	}

	/**
	 * @param {*} user 
	 * @param {Set<string>} [propsToCheck] 
	 * @returns 
	 */
	static hasErrors(user, propsToCheck) {
		let errors = {};
		const emailRegex = getEmailRegexp();
		const passRegex = /^(?=.*[0-9])(?=.*[a-zA-Z\u00C0-\u017f]).{6,64}$/;

		const shouldCheck = (key) => {
			if (propsToCheck) {
				return propsToCheck.has(key);
			}

			return user[key] !== undefined;
		};

		if (shouldCheck('email')) {
			// Email
			if (!emailRegex.test(user.email)) errors.email = 'Indtast venligst en korrekt email';
			if (user.email?.length === 0) errors.email = 'Indtast venligst en email';
		}

		if (shouldCheck('password')) {
			// Password
			if (!user.password?.match(passRegex)) errors.password = 'Ukendt fejl i kodeord';
			if (!user.password?.match(/\d+/g)) errors.password = 'Kodeord skal indeholde mindst 1 tal';
			if (!user.password?.match(/[a-zA-Z\u00C0-\u017f]/g)) errors.password = 'Kodeord skal indeholde mindst 1 bogstav';
			if (user.password?.length < 6) errors.password = 'Kodeord skal være mindst 6 karakterer';
			if (user.password?.length > 64) errors.password = 'Kodeord må maks. være 64 karakterer';
			if (user.password?.length === 0) errors.password = 'Indtast venligst et kodeord';
		}

		if (shouldCheck('repeatPassword')) {
			if (user.password !== user.repeatPassword) errors.repeatPassword = 'Kodeord er ikke ens';
		}

		if (shouldCheck('terms')) {
			if (user.terms !== true) errors.terms = 'Forretningssbetingelser skal accepteres';
		}

		if (shouldCheck('consent')) {
			if (user.consent !== true) errors.consent = 'Samtykkeerklæring skal accepteres';
		}

		if (shouldCheck('phone')) {
			if (!user.phone?.match(/^[ +0-9]*$/g)) errors.phone =  'Indtast venligst et korrekt telefon nummer';
			if (user.phone?.length < 2) errors.phone = 'Telefon skal være mindst 2 karakterer';
			if (user.phone?.length > 16) errors.phone = 'Telefon må maks. være 16 karakterer';
		}

		if (shouldCheck('firstname')) {
			if (!user.firstname) errors.firstname = 'Indtast venligst et fornavn';
			if (user.firstname?.length < 2) errors.firstname = 'Fornavn skal være mindst 2 karakterer';
			if (user.firstname?.length > 64) errors.firstname = 'Fornavn må maks. være 64 karakterer';
		}

		if (shouldCheck('lastname')) {
			if (!user.lastname) errors.lastname = 'Indtast venligst en efternavn';
			if (user.lastname?.length < 2) errors.lastname = 'Efternavn skal være mindst 2 karakterer';
			if (user.lastname?.length > 64) errors.lastname = 'Efternavn må maks. være 64 karakterer';
		}

		if (shouldCheck('className')) {
			if (!user.className) errors.className = 'Indtast venligst en klassenavn';
			if (user.className?.length < 2) errors.className = 'Klassenavn skal være mindst 2 karakterer';
			if (user.className?.length > 64) errors.className = 'Klassenavn må maks. være 64 karakterer';
		}

		if (shouldCheck('cvr')) {
			const error = cvrHasErrors(user.cvr);
			if (error) errors.cvr = 'Vælg venligst en virksomhed';
		    if (user.CVRError) errors.cvr = user.CVRError;
		}

		if (Object.keys(errors).length > 0) {
			return errors;
		}

		return false;
	}
}

export default AuthHelper;
