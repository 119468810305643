import React from 'react';
import { Loader, Segment } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import compose from 'lodash.flowright';
import { unregisterERP } from 'http/erpBroker';
import { getParam, unsetParam } from 'util/QueryParams';
import browserHistory from 'util/browserHistory';
import withUserData from 'util/withUserData';

import ERPList from './ERPList';

class ERPConnectorWidget extends React.Component {
	state = {
        selectedERP: null,
        service: null,
    };

    componentDidMount = () => {
        const erp = this.getERPFromURL();
        if (erp) {
            this.setState({ selectedERP: erp });
            unsetParam('erp');
        }
    };

    getERPFromURL = () => {
        // 1: try from params
        const erp = getParam('erp');
        if (erp) {
            return erp;
        }

        // 2: try from hash
        const { hash } = this.props.location;
        if (hash) {
            const selectedERP = hash.substr(1);
            return selectedERP;
        }

        // not found
        return null;
    };

    setActiveERP = erp => {
        if (this.state.selectedERP === erp) {
            erp = null;
        }

		this.setState({
            selectedERP: erp,
            error: null,
        });
    };

    notifyChange = async (...args) => {
        const { onERPChanged } = this.props;
        onERPChanged && onERPChanged(...args);
    };

	unregisterERP = async () => {
        this.setState({ loading: true });
        await unregisterERP();
        this.notifyChange();
        browserHistory.push(this.props.location.pathname);
    };

    onRegister = async (...args) => {
        this.notifyChange(...args);
    };

    renderContent = () => {
        const { toInteractiveElement } = this.props;
        const { loading, selectedERP } = this.state;

        // render spinner
        if (loading) {
            return (
                <Loader
                    inline='centered'
                    size='large'
                    active
                />
            );
        }

        // render list of available ERP systems
        return (
            <ERPList
                setActiveERP={this.setActiveERP}
                onRegister={this.onRegister}
                selectedERP={selectedERP}
                toInteractiveElement={toInteractiveElement}
            />
        );
    };
    
	render = () => {
        return (
            <Segment basic>
                {this.renderContent()}
            </Segment>  
        );
	};
}

export default compose(
    withRouter,
    withUserData,
)(ERPConnectorWidget);