function triggerDownload(data, filename) {
    const downloadLink = document.createElement('a');
    downloadLink.href = data
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

/**
 * Creates & clicks on a download link with the given blob.
 * Removes the link after download has been triggered.
 * @param {Blob} blob 
 * @param {string} filename 
 */
function downloadBlob (blob, filename) {
    if (window.navigator && window.navigator.msSaveBlob) {
        // Internet explorer
        window.navigator.msSaveBlob(blob, filename);
    } else {
        // Other browsers
        triggerDownload(URL.createObjectURL(blob), filename);
    }
}

/**
 * Triggers a download of content encoded as a base64 string
 * @param {string} contentType - URI content type, e.g. application/pdf 
 * @param {string} base64String - data encoded as base64 string
 * @param {string} base64filenameData - name of the file to download
 */
export function downloadBase64(contentType, base64String, filename) {
    const data = `data:${contentType};base64,${base64String}`
    triggerDownload(data, filename);
}

export default downloadBlob;