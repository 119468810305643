import React, { useEffect, useState } from 'react';

import AutoHeight from 'util/AutoHeight';
import HintContainer from '../hint/hint_containers/HintContainer';
import style from './HintWrapper.module.scss';

const HintWrapper = props => {
    const {
        productData,
        productMetadata,
        sectionFieldWrappers,
        hasPaid,
        factsBeingUpdated,
        hintWrapper,
        customHintProps,
        altContent
    } = props;

    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setOpacity(1); 
        }, 25);
    }, []);

    useEffect(() => {
        setOpacity(0);
        setTimeout(() => {
            setOpacity(1); 
        }, 25);
    }, [hintWrapper]);

    /**
     * Renders hint together with its container
     * 
     * @param {*} hint
     */
    const renderHint = hint => {
        const hintRender = <HintContainer
            hint={hint}
            hasPaid={hasPaid}
            factsBeingUpdated={factsBeingUpdated}
            productMetadata={productMetadata}
            productData={productData}
            sectionFieldWrappers={sectionFieldWrappers}
            customHintProps={customHintProps}
        />
        return hintRender;
    };

    /**
     * Renders alternative content with its container
     */
    const renderAltContent = altContent => {
        return (
            <div className={style['alt-content']}>
                {altContent}
            </div>
        );
    };

    // ==================================================

    const renderHintWrapper = () => {
        const shouldRenderHint = Boolean(hintWrapper.hint);
        let content = null;
        if(shouldRenderHint) {
            const { hint } = hintWrapper;
            content = renderHint(hint);
        } else {
            content = renderAltContent(altContent);
        }

        // ==================================================

        const { hint } = hintWrapper;

        const hintWrapperStyle = {
            borderStyle: hint ? 'none' : 'dashed',
            // backgroundColor: hint ? 'white' : 'transparent'
            backgroundColor: 'white'
        };

        const hintWrapperContentStyle = {
            opacity,
            transition: opacity === 1 ? 'opacity 0.5s linear' : ''
        };

        const hintWrapperRender = (
            <AutoHeight className={style['hint-wrapper']} style={hintWrapperStyle}>
                <div style={hintWrapperContentStyle}>
                    {content}
                </div>
            </AutoHeight>
        );

        return hintWrapperRender;
    };

    return renderHintWrapper();
};

export default HintWrapper;
