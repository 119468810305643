import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Loader, Message } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { USE_CUSTOM_DISCOUNT_SYSTEM } from 'flags';
import { refreshTokens } from 'network/fetch/refreshTokens';
import { calculatePurchaseDiscount, getSubscriptionsOverview } from 'http/payment';
import { getProductByID } from 'util/getProducts';
import { getParam } from 'util/QueryParams';
import { moms } from 'util/moms';
import useRestResource from 'util/useRestResource';
import useUser from 'util/useUser';
import useDeferredRedirect from 'util/useDeferredRedirect';
import SubscriptionPackageSelector from 'design/atoms/SubscriptionPackageSelector/SubscriptionPackageSelector';
import SubscriptionDetails from './SubscriptionDetails';
import PaymentOverview from '../shared/PaymentOverview';
import usePaymentState from '../shared/usePaymentState';
import PaymentPanels from '../shared/PaymentPanels';
import PaymentShell from '../shared/PaymentShell';
import AdditionalTaxYearsPicker, { AddToBasketButton } from './AdditionalTaxYearsPicker';
import sendGTMPurchaseEvent from './sendGTMPurchaseEvent';
import HighFive from '../shared/HighFive';
import { doPriceCalculations } from '../shared/doPriceCalculations';
import { getReportingPeriodChain } from 'http/financial-dates';
import getSubscriptionPackageUpgradeInfo, { SubscriptionPackageUpdateDescription } from '../shared/getSubscriptionPackageUpgradeInfo';
import { ShoppingCartSegment } from '../shared/ShoppingCart';
import { starterHelpOrderLineItem, STARTER_HELP_ID } from '../PaymentFlowStarterHelp';

const getProductCartInformation = async productID => {
    const [product, subscriptions] = await Promise.all([
        getProductByID(productID),
        getSubscriptionsOverview(),
    ]);

    const activeSubscription = subscriptions.find(subscription => {
        return subscription.productID === productID;
    });

    const cartInfo = {
        product,
        activeSubscription,
        firstTaxYear: null,
        subscriptionRenewalInfo: {
            renewalDay: 0,
            renewalMonth: 0,
        },
        tiers: [],
    };

    if (!activeSubscription) {
        if (product.renewalType === 'reportingPeriod') {
            const allPeriods = await getReportingPeriodChain();
            const [mostRecentPeriod] = allPeriods;
            cartInfo.subscriptionRenewalInfo.renewalDay = mostRecentPeriod.start.day;
            cartInfo.subscriptionRenewalInfo.renewalMonth = mostRecentPeriod.start.month;
            
            const firstPeriod = allPeriods.find(period => period.isFirstYear);
            if (firstPeriod) {
                cartInfo.firstTaxYear = firstPeriod.taxYear;
            }
        } else {
            cartInfo.subscriptionRenewalInfo.renewalDay = product.renewalDay;
            cartInfo.subscriptionRenewalInfo.renewalMonth = product.renewalMonth;
        }
    }


    if (!product.subscriptionPackageSet) {
        // no packages available - show default product information
        cartInfo.tiers.push({
            name: product.name,
            semanticIcon: product.semanticIcon,
            price: product.defaultPrice,
        });
        return cartInfo;
    }

    const tiers = product.subscriptionPackageSet.tiers.map(({ id, name, semanticIcon, price }) => {
        return {
            id,
            name,
            semanticIcon,
            price,
        };
    });

    cartInfo.tiers.push(...tiers);
    return cartInfo;
};

const PaymentFlowPackcage = () => {
    const { productID, taxYear } = useParams();
    const [selectedStepIdx, setSelectedStepIdx] = useState(0);
    const [chosenPackageIdx, setChosenPackageIdx] = useState(0);
    const [selectedTaxYears, setSelectedTaxYears] = useState([taxYear]);
    const [starterHelpPicked, setStarterHelpPicked] = useState(false);
    const user = useUser();

    const cartInfoFetcher = useCallback(() => getProductCartInformation(productID), [productID]);
    const { loading, error, data: cartInfo } = useRestResource({ fetcher: cartInfoFetcher });

    const discountFetcher = useCallback(() => {
        if (!USE_CUSTOM_DISCOUNT_SYSTEM()) {
            return Promise.resolve({ active: false });
        }
        
        if (productID !== 'aarsrapport_b') {
            return Promise.resolve({ active: false }); // TODO: remove when new campaign system is released
        }

        
        return calculatePurchaseDiscount(productID, selectedTaxYears);
    }, [productID, selectedTaxYears]);

    const discountResource = useRestResource({ fetcher: discountFetcher });

    const preselectedPackage = getParam('package');

    useEffect(() => {
        if (!preselectedPackage) return;
        if (!cartInfo) return;

        const idxOfSelectedPackage = cartInfo.tiers.findIndex(packageTier => {
            return packageTier.id === preselectedPackage;
        });

        if (idxOfSelectedPackage === -1) return;

        setChosenPackageIdx(idxOfSelectedPackage);
        setSelectedStepIdx(1);
    }, [preselectedPackage, cartInfo]);

    const shouldShowStarterHelp = (
        !user.purchases.includes(STARTER_HELP_ID) &&
        user.isErhverv()
    );

    const preselectStarterHelp = getParam('starterhelp');

    useEffect(() => {
        if (!shouldShowStarterHelp) return;
        if (preselectStarterHelp !== 'true') return;

        setStarterHelpPicked(true);
    }, [shouldShowStarterHelp, preselectStarterHelp]);

    const userOwnedTaxYears = user.ownedProducts[productID] || [];
    const unpickableTaxYears = [...userOwnedTaxYears, taxYear].map(year => Number(year));

    const orderLines = useMemo(() => {
        if (!cartInfo) {
            return [];
        }

        const pickedTier = cartInfo.tiers[chosenPackageIdx];

        const orderLines = [...selectedTaxYears].sort().map((taxYear, i) => {
            let discount;
            let beforeDiscountPrice;
            let discountAmount;
            let price;

            if (i >= 1) {
                discount = 25;
                beforeDiscountPrice = pickedTier.price;
                discountAmount = pickedTier.price * 0.25;
                price = beforeDiscountPrice - discountAmount;
            } else {
                price = pickedTier.price;
            }

            return {
                icon: pickedTier.semanticIcon,
                description: `${pickedTier?.name} ${taxYear}`,
                taxYear: taxYear,
                discount,
                beforeDiscountPrice,
                discountAmount,
                price,
                metadata: {
                    productPurchase: {
                        type: 'PRODUCT',
                        taxYear: taxYear,
                        productID,
                    },
                },
            };
        });

        if (starterHelpPicked) {
            orderLines.push(starterHelpOrderLineItem);
        }
        
        return orderLines;
    }, [selectedTaxYears, chosenPackageIdx, cartInfo, productID, starterHelpPicked]);

    const selectedPackageTier = (cartInfo?.tiers || [])[chosenPackageIdx]?.id;

    const paymentState = usePaymentState({
        orderLines,
        selectedPackageTier,
        discount: discountResource.data,
    });

    useDeferredRedirect(paymentState.paymentDone, getParam('redirect', '/'));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [selectedStepIdx]);

    const performPayment = async () => {
        const { success, paymentIntentID } = await paymentState.performPaymentRequest();
        if (!success) {
            return;
        }

        const { total, vat } = doPriceCalculations({
            orderLines: orderLines,
            proactiveVat: cartInfo.product.vat,
            discount: discountResource.data,
        });
    
        sendGTMPurchaseEvent({
            transactionID: paymentIntentID,
            orderLines: orderLines,
            totalPrice: total,
            vat,
        });

        await refreshTokens();
    };

    const renderPaymentOverview = ({ callToAction, hideCouponField }) => {
        return (
            <PaymentOverview
                working={USE_CUSTOM_DISCOUNT_SYSTEM() && discountResource.loading}
                proactiveVat={cartInfo.product.vat}
                discount={discountResource.data}
                orderLines={orderLines}
                onCouponChange={paymentState.setCoupon}
                initialCoupon={paymentState.couponCode}
                extraContent={callToAction}
                hideCouponField={hideCouponField}
            />
        );
    };

    const renderPackageSelector = () => {    
        const { subscriptionPackageSet } = cartInfo.product;

        const tiers = cartInfo.tiers.map((tier, tierIdx) => {
            return {
                ...tier,
                ...subscriptionPackageSet.tiers[tierIdx],
            };
        });

        return (
            <SubscriptionPackageSelector
                vat={cartInfo.product.vat}
                packageSet={{ tiers, features: subscriptionPackageSet.features }}
                onPackageSelected={packageIdx => {
                    setChosenPackageIdx(packageIdx);
                    setSelectedStepIdx(selectedStepIdx + 1);
                }}
            />
        );
    };

    const renderAdditionalSalesStep = () => {
        const selectedTier = cartInfo.tiers[chosenPackageIdx];

        const priceToShow = cartInfo.product.vat ? moms(selectedTier.price) : selectedTier.price;

        const starterHelpPicker = shouldShowStarterHelp && (
            <ShoppingCartSegment title='Opstartshjælp' icon='rocket'>
                <div style={{ marginBottom: '1em' }}>
                    Med opstartshjælpen kommer du godt i gang. Hvis du mangler sammenligningstal fra sidste år,
                    hjælper vi dig med at indtaste åbningsbalancen, så du kan komme trygt i gang uden fejl.
                </div>
                <div style={{ textAlign: 'right' }}>
                    <AddToBasketButton
                        onClick={() => setStarterHelpPicked(!starterHelpPicked)}
                        isChosen={starterHelpPicked}
                    />
                </div>
            </ShoppingCartSegment>
        );

        const additionalProductSegment = (
            <div>
                <AdditionalTaxYearsPicker
                    onTaxYearPicked={ty => {
                        const tys = ty.toString();
                        if (selectedTaxYears.includes(tys)) {
                            setSelectedTaxYears(selectedTaxYears.filter(x => x !== tys));
                        } else {
                            setSelectedTaxYears([...selectedTaxYears, tys]);
                        }
                    }}
                    pickedTaxYears={selectedTaxYears.map(x => Number(x))}
                    icon={selectedTier.semanticIcon}
                    blacklist={unpickableTaxYears}
                    totalPrice={priceToShow}
                    discount={priceToShow * 0.25}
                    centerTaxYear={Number(taxYear)}
                    firstTaxYear={cartInfo.firstTaxYear}
                />
                {starterHelpPicker}
            </div>
        );

        return (
            <PaymentPanels
                leftPanel={additionalProductSegment}
                rightPanel={renderPaymentOverview({
                    hideCouponField: false,
                    callToAction: (
                        <Button
                            primary
                            fluid
                            icon='arrow right'
                            labelPosition='right'
                            content='Forsæt til betaling'
                            onClick={() => setSelectedStepIdx(prev => prev + 1)}
                        />
                    ),
                })}
            />
        );
    };

    const renderPaymentStep = () => {
        const selectedTier = cartInfo.tiers[chosenPackageIdx];
        const shouldSignSubscription = !cartInfo.activeSubscription;
        const subscriptionUpdateInfo = getSubscriptionPackageUpgradeInfo(
            cartInfo.product,
            cartInfo.activeSubscription?.selectedPackageTier,
            selectedTier.id,
        );

        return (
            <PaymentPanels
                leftPanel={
                    <>
                        {paymentState.renderPaymentMethod()}
                        {shouldSignSubscription && (
                            <SubscriptionDetails
                                subscription={{
                                    productName: selectedTier.name,
                                    anchorDay: cartInfo.subscriptionRenewalInfo.renewalDay,
                                    anchorMonth: cartInfo.subscriptionRenewalInfo.renewalMonth,
                                }}
                            />
                        )}
                        {subscriptionUpdateInfo && (
                            <SubscriptionPackageUpdateDescription
                                fromTier={subscriptionUpdateInfo.prevTier.name}
                                toTier={subscriptionUpdateInfo.nextTier.name}
                            />
                        )}
                    </>
                }
                rightPanel={renderPaymentOverview({
                    hideCouponField: true,
                    callToAction: (
                        <>
                            <Button
                                primary
                                fluid
                                icon='credit card outline'
                                content='Betal'
                                disabled={paymentState.working || !paymentState.canPay}
                                loading={paymentState.working}
                                onClick={performPayment}
                            />
                            {paymentState.renderError()}
                        </>
                    ),
                })}
            />
        );
    };

    const prepareSteps = () => {
        if (!cartInfo) {
            return [];
        }

        const steps = [
            {
                icon: 'tag',
                title: 'Få rabat',
                render: renderAdditionalSalesStep,
            },
            {
                icon: 'credit card',
                title: 'Betaling',
                render: renderPaymentStep,
            },
        ];

        if (cartInfo.tiers.length > 1) {
            steps.unshift({
                icon: 'box',
                title: 'Vælg pakke',
                render: renderPackageSelector,
                stepperNavigationDisabled: true,
            });
        }

        return steps;
    };

    const steps = prepareSteps();
    const selectedStep = steps[selectedStepIdx];

    const renderContent = () => {
        if (loading) {
            return (
                <Loader
                    size='huge'
                    inline='centered'
                    active
                />
            );
        }

        if (error) {
            return <Message error content='Der opstod en fejl' />;
        }

        if (paymentState.paymentDone) {
            return <HighFive />;
        }

        return selectedStep.render()
    };

    return (
        <PaymentShell
            title={`Få adgang til skatteår ${taxYear}`}
            paymentDone={paymentState.paymentDone}
            disabled={paymentState.working || selectedStep?.stepperNavigationDisabled}
            steps={steps}
            selectedStepIdx={selectedStepIdx}
            onSelectedStepChange={setSelectedStepIdx}
            children={renderContent()}
        />
    );
};

export default PaymentFlowPackcage;