import { decodeAccessToken } from 'util/token';
import { getFlags } from '../http/feature-flags';
import { isAdmin } from 'util/userMethods';

let _flags = {};

/**
 * Loads all current flag values into memory
 */
export async function loadFlags () {
    const resp = await getFlags();
    _flags = formatFlags(resp);
}

function formatFlags (flags) {
    const flagMap = {};
    flags.forEach(({ name, value }) => flagMap[name] = value);
    return flagMap;
}

/**
 * Returns a function for getting the provided flag
 * @param {string} flagName
 * @returns {() => boolean}
 */
function flagGetter (flagName, adminOverride = false) {
    /**
     * @returns {boolean}
     */
    return () => {
        if (adminOverride && isAdmin(decodeAccessToken())) {
            return true;
        }

        return _flags[flagName] || false;
    };
}

export const BUSINESSCENTRAL_ERP = flagGetter('BUSINESSCENTRAL_ERP');
export const SWEDISH_SIE_ERP = flagGetter('SWEDISH_SIE_ERP');
export const SHOW_CHAT_HISTORY = flagGetter('SHOW_CHAT_HISTORY');
export const HINT_SECTION = flagGetter('HINT_SECTION');
export const CLOSING_SHEET_QUESTIONS = flagGetter('CLOSING_SHEET_QUESTIONS');
export const USE_CUSTOM_DISCOUNT_SYSTEM = flagGetter('USE_CUSTOM_DISCOUNT_SYSTEM');