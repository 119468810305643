import ToolTip from 'design/atoms/Tooltip';
import React from 'react';
import { Message } from 'semantic-ui-react';
import { formatDate } from 'util/format/DateTime';

function TeaserMessage ({ taxYear, taxYearStartDate }) {

    const tooltipContent = <>
        <b>Hvorfor er produktet i en prøvetilstand?</b>
        <p>
            Fordi indberetningsperioden for skatteåret {taxYear} er ikke gået i gang endnu.
        </p>

        <b>Hvad vil det sige, at produktet er i en prøvetilstand?</b>
        <p>
            Det vil sige, at underskrivning af årsrapporten er slået fra.
            Det er slået fra, fordi skatteåret ikke er slut endnu og tallene stadig kan ændre sig.
        </p>

        <b>Hvornår kan jeg få fuld adgang til produktet?</b>
        <p>
            Du kan få fuld adgang til produktet, når indberetningsperioden for skatteår {taxYear} går i gang, hvilket
            sker d. {formatDate(taxYearStartDate)}.
        </p>

        <b>Andre spørgsmål?</b>
        <p>
            Har du spørgsmål angående produktet i prøvetilstand, eller er du i tvivl om noget helt andet, 
            er du altid velkommen til at kontakte os i chatten.
        </p>
    </>;

    return <div style={{ textAlign: 'center' }}>
        <Message compact color='yellow'>
            <b>OBS:</b> Du anvender produktet i en prøvetilstand
            <ToolTip data={tooltipContent} position='top right' />
        </Message>
    </div>;
}

export default TeaserMessage;