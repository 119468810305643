import React, { useState } from 'react';
import { Button, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';

function FlowUpdateButton ({ onUpdate }) {
    const [working, setWorking] = useState(false);

    return (
        <Segment size='tiny' textAlign='center' basic>
            <Button
                content='Updater flow'
                labelPosition='left'
                icon='sync'
                size='medium'
                loading={working}
                disabled={working}
                onClick={async () => {
                    try {
                        setWorking(true);
                        await onUpdate();
                    } catch {
                        toast.error('Kunne ikke opdatere signaturflowet');
                    } finally {
                        setWorking(false);
                    }
                }}
            />
        </Segment>
    );
}

export default FlowUpdateButton;