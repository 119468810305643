import React, { Component } from 'react';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import DynamicNumber from '../../../util/react-dynamic-number';
import styles from './NumberInput.module.scss';

class NumberInput extends Component {
	normalizeValue = val => {
		val = val.replace(/\./g, '');
		val = val.replace(',', '.');
		return Number(val);
	};

    handleChange = e => {
        this.props.onChange(this.normalizeValue(e.target.value));
	};

	handleFocus = e => {
		e.target.select();
	};

	getClassName = () => {
		let cnames = [ styles.numinput ];
		if (this.props.className) {
			cnames.push(this.props.className);
		}
		return classNames(...cnames);
	};

    render () {
		const {
			value,
			suffix,
			leftAttachment,
			debounceTime,
			disabled,
			transparent,
			autoFocus,
			fraction,
		} = this.props;
		
        return (
			<DynamicNumber
                onChange={debounce(this.handleChange, debounceTime)}
                onFocus={this.handleFocus}
				className={this.getClassName()}
				disabled={disabled}
				suffix={suffix}
				transparent={transparent}
				initValue={value}
				value={value}
				leftAttachment={leftAttachment}
				autoFocus={autoFocus}
				integer={64}
				fraction={fraction ?? 2}
				separator={','}
				thousand
				positive
				negative
			/>
        );
    }
}

NumberInput.defaultProps = {
	suffix: '',
	debounceTime: 200,
};

export default NumberInput;
