import React from 'react';

import style from './HintBasic.module.scss';

const HintBasic = props => {
    const { hint } = props;
    const { title, description } = hint.data;

    const renderTitle = () => {
        if(!title) {
            return null;
        }

        return (
            <div className={style['title-container']}>
                <p><b>{title}</b></p>
            </div>
        );
    };

    const renderDescription = () => {
        if(!description) {
            return null;
        }

        return (
            <div className={style['description-container']}>
                <p>{description}</p>
            </div>
        );
    };

    return (
        <div className={style['hint-content']}>
            {renderTitle()}
            {renderDescription()}
        </div>
    );
};

export default HintBasic;
