import { HTTPClient } from "../network/fetch";

const client = new HTTPClient({ prefix: 'report-editor' });

export function executeTemplate({ productID, productLabel, yearReportDataNodeID, variables, overrideConfiguration, logoID, isInternal, language, isFirstYear, reportTemplateID, reportTemplateVersion }) {
    return client.postResource(`/exported-templates/execute-product-engine-template`, {
        productID,
        productLabel,
        yearReportDataNodeID,
        variables,
        overrideConfiguration,
        logoID,
        isInternal,
        language,
        isFirstYear,
        reportTemplateID,
        reportTemplateVersion,
    });
}

export function getTemplateBlocks(templateID, templateVersion) {
    return client.getResource(`/exported-templates/${templateID}/${templateVersion}/blocks`);
}