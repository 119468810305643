import React, { useState } from 'react';
import { Button, Card, Icon } from 'semantic-ui-react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ShoppingCartSegment } from '../shared/ShoppingCart';
import ColoredText from 'design/atoms/ColoredText';
import { formatNumber } from 'util/format/Number';
import { useBreakpoints } from 'design/atoms/Media';

export const AddToBasketButton = ({ onClick, isChosen, ...props }) => {
    const [hover, setHover] = useState(false);

    return (
        <Button
            onMouseEnter={() => isChosen && setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={onClick}
            primary
            basic={isChosen}
            size='mini'
            icon={isChosen ? (hover ? 'x' : 'check') : 'plus'}
            content={isChosen ? (hover ? 'Fjern fra kurv' : 'Lagt i kurv') : 'Læg i kurv'}
            {...props}
        />
    );
};

const CardItem = ({ icon, taxYear, price, discount, isChosen, onChoose, ...cardProps }) => {
    return (
        <Card
            {...cardProps}
            link
            style={{
                color: 'black',
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 10px',
            }}
        >
            <Card.Header>
                <div style={{ fontWeight: 'bold', fontSize: '185%', height: '20px', marginTop: '0.75em', marginBottom: '0.75em', textAlign: 'center' }}>
                    <Icon name={icon} color='green' /> {taxYear}
                </div>
            </Card.Header>

            <Card.Content textAlign='center'>
                <span style={{ textDecoration: 'line-through', opacity: 0.75 }}>{formatNumber(price)} kr.</span><br /><b>{formatNumber(price - discount)}</b> kr.

                <div style={{ marginTop: '0.75em' }}>
                    <Icon name='tag' color={discount > 0 ? 'green' : 'grey'} />
                    {discount > 0 && (
                        <>
                            Spar{' '}
                            <ColoredText color='green'>
                                <strong>
                                    {formatNumber(discount)}
                                </strong>{' '}
                                kr.
                            </ColoredText>
                        </>
                    )}
                </div>
            </Card.Content>

            <Card.Content textAlign='center'>
                <AddToBasketButton
                    onClick={() => onChoose(taxYear)}
                    isChosen={isChosen}
                    fluid
                />
            </Card.Content>
        </Card>
    );
};

const getVisibleTaxYears = (centerTaxYear, firstTaxYear, blacklist = []) => {
    const years = [];

    const lowestPurchasableTaxYear = firstTaxYear || 2019;
    const lower = Math.max(lowestPurchasableTaxYear, centerTaxYear - 10);
    const upper = centerTaxYear + 10;

    let closestAvailableTaxYear = -1;

    for (let year = lower; year <= upper; year++) {
        if (blacklist.includes(year)) {
            continue;
        }

        years.push(year);

        if (year < centerTaxYear) {
            continue;
        }

        if (Math.abs(centerTaxYear - year) < Math.abs(centerTaxYear - closestAvailableTaxYear)) {
            closestAvailableTaxYear = year;
        }
    }

    return { years, closestAvailableTaxYear };
};

const AdditionalTaxYearsPicker = ({ pickedTaxYears, icon, blacklist, onTaxYearPicked, totalPrice, discount, centerTaxYear, firstTaxYear }) => {
    const { years, closestAvailableTaxYear } = getVisibleTaxYears(centerTaxYear, firstTaxYear, blacklist);
    const idxOfClosest = years.indexOf(closestAvailableTaxYear) - 1;
    const breakpoints = useBreakpoints();

    const taxYearItems = years.map((year) => {
        return (
            <div style={{ width: '100%' }}>
                <div style={{ padding: '1em' }}>
                    <CardItem
                        taxYear={year}
                        icon={icon}
                        price={totalPrice}
                        discount={Math.round(discount)}
                        isChosen={pickedTaxYears.includes(year)}
                        onChoose={() => onTaxYearPicked(year)}
                    />
                </div>
            </div>
        );
    });

    const amountOfItemsToView = breakpoints.select({
        'gte computer': 3,
        'default': 1,
    });

    return (
        <ShoppingCartSegment
            icon='plus' 
            title='Køb flere skatteår nu og få 25 % rabat pr. stk.'
            background='rgba(230, 230, 230, 0.25)'
        >
            <div style={{ marginLeft: '1em', marginRight: '1em' }}>
                <Slider
                    speed={500}
                    arrows={true}
                    infinite={false}
                    slidesToScroll={amountOfItemsToView}
                    slidesToShow={amountOfItemsToView}
                    initialSlide={idxOfClosest}
                    children={taxYearItems}
                />
            </div>
        </ShoppingCartSegment>
    );
};

export default AdditionalTaxYearsPicker;
