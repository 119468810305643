import JWTDecode from 'jwt-decode';
import intersection from 'lodash.intersection';
import get from 'lodash.get';
import capitalize from './capitalize';
import {
    ADMIN,
    PRIVAT,
    ERHVERV,
    REVISOR,
    HOLDING,
    DINERO_STAFF,
    VIRKSOMHED,
    SELSKAB,
    SEGMENT_PRIVAT,
    SEGMENT_ERHVERV,
    SEGMENT_AKADEMI,
    EXTERNAL_MODEL_BUILDER,
} from './userMethods';

// complete list of entity roles can be found here:
// `accounts` sevice in /src/util/IAM
export const entityRoleLevels = {
    owner: 0,
    administrator: 1,
    accountant: 2,
};

// The following schema is based on what is returned from
// "createAccessTokenPayload" in the accounts service.
// You can find it here:
// createAccessTokenPayload at "src/util/token/accessToken.js"

class TokenUser {
    /** @type {string} */ tokenType;
    /** @type {string} */ email;
    /** @type {string} */ displayName;
    /** @type {string} */ companyName;
    /** @type {boolean} */ isVerified;
    /** @type {boolean} */ showAccountantFeatures;
    /** @type {Date} */ createdAt;
    /** @type {string} */ cvr;
    /** @type {string} */ businessForm;
    /** @type {string} */ businessCategory;
    /** @type {string} */ erp;
    /** @type {string} */ firstname;
    /** @type {string} */ lastname;
    /** @type {boolean[]} */ consent;
    /** @type {string} */ id;
    /** @type {string[]} */ roles;
    /** @type {boolean} */ suspended;
    /** @type {boolean} */ noEntities;
    /** @type {string[]} */ accountants;
    /** @type {string} */ creator;
    /** @type {string} */ logo;
    /** @type {string[]} */ activeProducts;
    /** @type {string[]} */ purchases;
    /** @type {Object} */ login;
    /** @type {{[productID:string]: string[]}} */ ownedProducts;
    /** @type {boolean} */ impersonation;
    /** @type {boolean} */ impersonatingOwnClient;
    /** @type {TokenUser} */ impersonatorData;

    constructor(rawToken) {
        const parsed = rawToken && JWTDecode(rawToken);
        this.createdAt = new Date(this.createdAt);
        Object.assign(this, parsed);
    }

    hasRole (role) {
        return !!this.roles && this.roles.includes(role);
    }
    
    isAdmin () {
        return this.hasRole(ADMIN);
    }
    
    isPrivat () {
        return this.hasRole(PRIVAT);
    }
    
    isErhverv () {
        return this.hasRole(ERHVERV);
    }
    
    isAccountant () {
        return this.hasRole(REVISOR);
    }
    
    isHolding () {
        return this.hasRole(HOLDING);
    }
    
    isDineroStaff () {
        return this.hasRole(DINERO_STAFF);
    }

    isExternalModelBuilder () {
        return this.hasRole(EXTERNAL_MODEL_BUILDER);
    }
    
    isClient () {
        return !!this.creator;
    }
    
    isVirksomhed () {
        return this.businessCategory === VIRKSOMHED;
    }
    
    isSelskab () {
        return this.businessCategory === SELSKAB;
    }
    
    isSegmentPrivat() {
        return this.login?.segment === SEGMENT_PRIVAT;
    }
    
    isSegmentErhverv() {
        return this.login?.segment === SEGMENT_ERHVERV;
    }

    isSegmentAkademi() {
        return this.login?.segment === SEGMENT_AKADEMI;
    }
    
    getSegmentIcon() {
        if (this.isSegmentPrivat()) {
            return 'user';
        }

        return 'building';
    }
    
    getUserType () {
        if (this.isErhverv()) {
            return ERHVERV;
        }
    
        return PRIVAT;
    }
    
    hasProductAccess (productID) {
        const { ownedProducts } = this;
        if (!ownedProducts) {
            return false;
        }
        const product = ownedProducts[productID];
        return !!product && product.length > 0;
    }
    
    getDisplayName () {
        if (this.displayName) {
            return this.displayName;
        }
        if (this.companyName) {
            return this.companyName;
        }

        const [firstName, lastName] = [this.firstname, this.lastname].map(name => {
            return (
                name
                .split(' ')
                .filter(x => x)
                .map(namePart => capitalize(namePart))
                .join(' ')
            );
        })

        return `${firstName} ${lastName}`;
    }
    
    /**
     * Checks if the user has access to the given product for the given tax year
     * @param {string} productID
     * @param {string} taxYear
     */
    ownsTaxYear (productID, taxYear) {
        const { ownedProducts } = this;
        if (!ownedProducts) {
            return false;
        }
        const product = ownedProducts[productID];
        return !!product && product.includes(`${taxYear}`);
    }
    
    /**
     * Gets an array of the products that the user has bought
     * @returns {String[]} a list of product ids
     */
    getPurchasedProducts () {
        const { ownedProducts } = this;
        if (!ownedProducts) {
            return [];
        }
        const out = [];
        for (let productID in ownedProducts) {
            if (ownedProducts[productID].length > 0) {
                out.push(productID);
            }
        }
        return out;
    }
    
    /**
     * Check if the user has an ACTIVE subscription (not if the user owns a tax year for the given product)
     * @param {string} productID 
     */
    hasActiveSubscription (productID) {
        const { activeProducts } = this;
        if (!activeProducts) {
            return false;
        }
        return activeProducts.includes(productID);
    }
    
    isSuspended () {
        return !!this.suspended;
    }
    
    /**
     * Checks if the given user has the rights to view the provided product
     * @param {*} product 
     */
    isAllowedToViewProduct (product) {
        const { allowedBusinessForms, roles } = product;
    
        // Check if the user has the correct business form
        if (allowedBusinessForms) {
            if (!allowedBusinessForms.includes(this.businessForm)) {
                return false;
            }
        }
    
        // Check if the user has one of the required roles
        if (intersection(this.roles, roles).length === 0) {
            return false;
        }
        
        return true;
    }
    
    /**
     * Gets a list of the users owned tax years
     * @param {*} productID 
     * @returns 
     */
    getOwnedTaxYears (productID) {
        return get(this, `ownedProducts.${productID}`, []);
    }

    /**
     * Gets the entity role level of the user.
     * Lower level means higher authority.
     * @returns {number}
     */
    getEntityRoleLevel() {
        const roleLevel = this.login?.roleLevel;

        if (typeof roleLevel !== 'number') {
            return Infinity;
        }

        return roleLevel;
    }

    isEntitySuperAdmin() {
        return this.getEntityRoleLevel() === entityRoleLevels.owner;
    }

    isEntityAdmin() {
        return this.getEntityRoleLevel() <= entityRoleLevels.administrator;
    }

    isEntityAccountant() {
        return this.getEntityRoleLevel() <= entityRoleLevels.accountant;
    }

    /**
     * Checks if this user has a higher level of authority than the provided auth level
     * @param {*} levelToBeat 
     * @returns 
     */
    hasHigherAuthority(levelToBeat) {
        return this.getEntityRoleLevel() < levelToBeat;
    }
}

export default TokenUser;