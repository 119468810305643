import React from 'react';
import { Button, Segment } from 'semantic-ui-react';

function ResetButton ({ onReset }) {
    return (
        <Segment size='tiny' textAlign='center' basic>
            <Button
                content='Reset til før signaturflow'
                labelPosition='left'
                icon='repeat'
                size='small'
                onClick={onReset}
            />
        </Segment>
    );
}

export default ResetButton;