import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Menu as SemMenu, Container, Dropdown, Message, Icon } from 'semantic-ui-react';
import compose from 'lodash.flowright';
import cn from 'classnames';
import { isImpersonating, stopImpersonation } from 'util/impersonation';
import { isAccountant } from 'util/userMethods';
import { withRootUserData } from 'util/withUserData';
import { useRootUser } from 'util/useUser';
import AuthHelper from 'util/AuthHelper';
import { Media } from 'design/atoms/Media';
import CompanyPicker from 'design/molecules/CompanyPicker';
import LanguagePicker from 'design/atoms/LanguagePicker';
import CreditsIcon from 'design/atoms/CreditsIcon';
import { useCreditsContext } from '../CreditsProvider';
import NotificationCenter from './NotificationCenter';
import MenuLogo from './MenuLogo';
import styles from './Menu.module.scss';

function MenuItem({ className, ...props }) {
    return (
        <SemMenu.Item className={cn(className, styles.compact)} {...props} />
    );
}

const CreditsMenuItem = () => {
    const { credits, initialFetchDone, error } = useCreditsContext();
    const user = useRootUser();
    const history = useHistory();

    const renderContent = () => {
        if (!initialFetchDone) {
            return <Icon name='spinner' loading />;
        }
        const formatter = new Intl.NumberFormat();
        return (
            <span>
                <CreditsIcon orange />{' '}
                {formatter.format(credits)}
            </span>
        );
    };

    if (!user.isAccountant()) {
        return null;
    }

    if (error) {
        return null;
    }

    const onClick = (
        user.isEntityAdmin()
            ? () => history.push('/betaling/credits')
            : undefined
    );

    return (
        <SemMenu.Item
            content={renderContent()}
            link={!!onClick}
            onClick={onClick}
        />
    );
};

class Menu extends React.Component {
    state = {
        showMenu: false,
        showImpersonationModal: false,

        serviceMessageBody: '',
        serviceMessageDismissed: !!window.sessionStorage.serviceMessageDismissed,
    };

    dismissServiceMessage = () => {
        window.sessionStorage.serviceMessageDismissed = true;
        this.setState({ serviceMessageDismissed: true });
    };

    handleLogout = () => {
        AuthHelper.deAuthenticate();
    };

    renderLanguagePicker = () => {
        return <MenuItem content={<LanguagePicker />} />;
    };

    toggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu,
        });
    };

    getLogoMenuItem = () => {
        return <MenuLogo onClick={() => this.goToHome(false)} />;
    };

    goToHome = (exitImpersonation = false) => {
        if (exitImpersonation && isImpersonating()) {
            stopImpersonation('/');
        } else {
            this.props.history.push('/');
        }
    };

    onSettingsClicked = () => {
        const link = '/indstillinger';
        if (isImpersonating()) {
            if (window.location.pathname !== link) {
                stopImpersonation(link);
            } else {
                stopImpersonation();
                window.location.reload();
            }
        } else {
            this.props.history.push(link);
        }
    };

    getLeftMenuItems = (Component = MenuItem) => {
        const { userData } = this.props;
        const pathname = window.location.pathname;
        const menuItems = [];

        if (isAccountant(userData)) {
            // render client list and settings menu items
            menuItems.push(
                <Component
                    key='clients'
                    active={pathname === '/' && !isImpersonating()}
                    content='Klientliste'
                    icon='users'
                    onClick={() => this.goToHome(true)}
                />,
            );
        }
        
        return menuItems;
    };

    shouldRenderCompanyPicker = () => {
        const { userData } = this.props;

        if (userData.noEntities) {
            return false;
        }

        const erhvervOrAdmin = userData.isErhverv() || userData.isAdmin();
        if (!erhvervOrAdmin) {
            return false;
        }

        return true;
    };

    getUserMenuItems = (Component = MenuItem) => {
        const { userData } = this.props;
        const pathname = window.location.pathname;
        const hasEntities = !userData.noEntities;

        let accountantCourseMenuItem;
        if (isAccountant(userData)) {
            const path = '/raadgiverkursus';
            const isActive = pathname.startsWith(path);
            accountantCourseMenuItem = (
                <Component
                    active={isActive}
                    key='raadgiverkursus'
                    content='Kursus'
                    icon='university'
                    onClick={() => {
                        if (!isActive) {
                            this.props.history.push(path + '/index');
                        }
                    }}
                />
            );
        }

        const settings = (
            hasEntities &&
            <Component
                key='options'
                active={pathname?.startsWith('/indstillinger')}
                content='Indstillinger'
                icon='cogs'
                onClick={this.onSettingsClicked}
            />
        );

        const notifications = <NotificationCenter />;

        const logOutComponentProps = {
            key: 'logout',
            icon: 'sign out',
            onClick: this.handleLogout,
            link: Component === MenuItem ? true : undefined,
        };

        const logOut = (
            <>
                <Media gte='tablet'>
                    <Component {...logOutComponentProps} />
                </Media>
                <Media lt='tablet'>
                    <Component {...logOutComponentProps} content='Log ud' />
                </Media>
            </>
        );

        const companyPicker = this.shouldRenderCompanyPicker() && (
            <CompanyPicker />
        );
        
        const creditsOverview = <CreditsMenuItem />;

        return [accountantCourseMenuItem, creditsOverview, companyPicker, settings, notifications, logOut];
    };

    renderMenuComputer = () => {
        const menuMenu = (
            <SemMenu.Menu
                key='usermenu'
                position='right'
                children={this.getUserMenuItems()}
            />
        );

        const menuItems = [
            this.getLogoMenuItem(),
            ...this.getLeftMenuItems(),
            menuMenu,
        ];

        return (
            <Media gte='tablet'>
                <SemMenu
                    children={menuItems}
                    attached='bottom'
                    size='small'
                    inverted
                    secondary
                />
            </Media>
        );
    };

    renderMenuMobile = () => {
        const { history } = this.props;
        const user = this.props.userData;
        const leftMenuItems = this.getLeftMenuItems(Dropdown.Item);
        const burgerMenu = (
            <Dropdown item icon='bars' key='burgerMenu'>
                <Dropdown.Menu>
                    {leftMenuItems}
                    {user.isAdmin() &&
                        <Dropdown.Item
                            content='Impersonering'
                            icon='spy'
                            onClick={() => history.push('/impersonation-search')}
                        />
                    }
                    {this.getUserMenuItems(Dropdown.Item)}
                </Dropdown.Menu>
            </Dropdown>
        );

        const logo = (
            <SemMenu.Menu
                key='logo'
                position='right'
                children={this.getLogoMenuItem()}
            />
        );

        const menuItems = [burgerMenu, logo];

        return (
            <Media lt='tablet'>
                <SemMenu
                    children={menuItems}
                    attached='bottom'
                    size='small'
                    inverted
                    secondary
                />
            </Media>
        );
    };

    renderServiceMessage = () => {
        const { serviceMessageDismissed, serviceMessageBody } = this.state;

        if (!serviceMessageBody) {
            return null;
        }

        if (serviceMessageDismissed) {
            return null;
        }

        return (
            <Message
                style={{ margin: 0, borderRadius: 0 }}
                size='small'
                color='blue'
                content={
                    <Container>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flexGrow: 1 }}>
                                <Icon name='info circle' />
                                {serviceMessageBody}
                            </div>
                            <div>
                                <Icon
                                    onClick={() => this.dismissServiceMessage()}
                                    name='close'
                                    link
                                />
                            </div>
                        </div>
                    </Container>
                }
            />
        );
    };

    renderMenu = () => {
        return (
            <>
                {this.renderMenuComputer()}
                {this.renderMenuMobile()}
            </>
        );
    };

    render = () => {
        return <>
            <div className={styles.headerContainer}>
                <Container>{this.renderMenu()}</Container>
            </div>
            {this.renderServiceMessage()}
        </>;
    };
}

export default compose(withRouter, withRootUserData)(Menu);