import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { TextArea, Label, Popup } from 'semantic-ui-react';
import { getFactValueByTag } from 'http/productEngine';
import ColoredText from 'design/atoms/ColoredText';
import Input from 'design/atoms/Input';
import Form from 'design/atoms/Form';
import FileAttachmentWidget from 'design/molecules/FileAttachmentWidget';

class InputFieldStringWidget extends React.Component {
    constructor() {
        super();
        this.onChange = debounce(this.onChange.bind(this), 600);
    }

	state = {
		previousText: '',
		previousTextRerenderKey: 0,
		previousTextPopupOpen: false,
		previousTextStatusMessage: null,

		typing: false,
		working: false,
	};

    static propTypes = {
		defaultValue: PropTypes.string,
		isMultiline: PropTypes.bool,
		label: PropTypes.string,
		error: PropTypes.string,
        isLocked: PropTypes.bool,
		tooltip: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
            PropTypes.array,
        ]),
	};

	static defaultProps = {
		error: null,
		defaultValue: '',
	};

	closePopup = () => {
		this.setState({ previousTextPopupOpen: false, previousTextStatusMessage: null });
	};

	componentDidMount = async () => {
		const { primoModelData, productMetadata, supply, allowImportingLastYearValue } = this.props;

		window.addEventListener('scroll', this.closePopup);

		if (!allowImportingLastYearValue) {
			return;
		}

		if (!primoModelData?.exists) {
			return;
		}

		if (!primoModelData?.isValidPrimoModel) {
			return;
		}

		const questionSupplyFact = productMetadata.facts[supply];
		if (!questionSupplyFact) {
			return;
		}

		const previousTextFact = await getFactValueByTag(primoModelData.modelID, primoModelData.label, questionSupplyFact.tag);


		this.setState({ previousText: previousTextFact?.string });
	};

	componentWillUnmount = () => {
		window.removeEventListener('scroll', this.closePopup);
	};

	commitTextUpdate = str => {
		const { onChange, supply } = this.props;
		return onChange(supply, { string: str });
	};

	onChange = str => {
        this.commitTextUpdate(str);
	};

	importLastYearText = async () => {
		this.setState({ working: true });

		let { previousTextRerenderKey } = this.state

		try {
			await this.commitTextUpdate(this.state.previousText);

			// causes the text field to rerender w/ the new text
			previousTextRerenderKey++;

			this.closePopupTimeout = window.setTimeout(() => this.closePopup(), 1500);
		} catch {
			this.setState({
				working: false,
				previousTextStatusMessage: {
					success: false,
					message: 'Teksten kunne ikke indlæses',
				},
			});
			return;
		}
		
		this.setState({
			working: false,
			previousTextRerenderKey,
			previousTextStatusMessage: {
				success: true,
				message: 'Tekst indlæst',
			},
		});
	};

	withImportLastYearTextPopup = trigger => {
		if (!this.state.previousText) {
			return trigger;
		}

		const { working, previousTextPopupOpen, previousTextStatusMessage } = this.state;

		let popupContent;
		if (previousTextStatusMessage) {
			popupContent = (
				<ColoredText
					bold
					iconPosition='left'
					icon={previousTextStatusMessage.success ? 'check circle' : 'exclamation circle'}
					color={previousTextStatusMessage.success ? 'green' : 'red'}
					content={previousTextStatusMessage.message}
				/>
			)
		} else {
			popupContent = (
				<ColoredText
					link
					bold
					disabled={working || this.props.isLocked}
					loading={working}
					icon='exchange'
					iconPosition='left'
					content='Indlæs sidste års tekst'
					underlined={false}
					onClick={this.importLastYearText}
				/>
			);
		}

		return (
			<Popup
				size='tiny'
				on='click'
				position='left center'
				style={{ border: 'none' }}
				trigger={trigger}
				open={previousTextPopupOpen}
				onClose={this.closePopup}
				content={popupContent}
				onOpen={() => {
					window.clearTimeout(this.closePopupTimeout);
					this.setState({ previousTextPopupOpen: true });
				}}
			/>
		);
	};

	renderMultilineQuestion = () => {
		const { defaultValue, isLocked } = this.props;

		let textarea = (
			<TextArea
				key={this.state.previousTextRerenderKey}
				onChange={e => this.onChange(e.target.value)}
				defaultValue={defaultValue}
				disabled={isLocked}
				rows={6}
			/>
		);

		if (isLocked) {
			textarea = (
				<div style={{ cursor: 'not-allowed' }}>
					{textarea}
				</div>
			);
		}

		return this.withImportLastYearTextPopup(textarea);
	};

	renderInputField = () => {
		const { defaultValue, isMultiline, suffix, isLocked } = this.props;

		if (isMultiline) {
			return this.renderMultilineQuestion();
		}

		const inputField = (
			<Input
				key={this.state.previousTextRerenderKey}
				onChange={this.onChange}
				defaultValue={defaultValue}
				disabled={isLocked}
				leftAttachment={<FileAttachmentWidget {...this.props} />}
				rightAttachment={suffix && <Label content={suffix} />}
			/>
		);

		return this.withImportLastYearTextPopup(inputField);
	};

	render () {
		const { label, tooltip, youtubeVideo, error } = this.props;
		return (
			<Form.Row>
				<Form.LabelField
					label={label}
					tooltip={tooltip}
					youtubeVideo={youtubeVideo}
				/>
				<Form.Field error={error} textAlign='right'>
					{this.renderInputField()}
				</Form.Field>
			</Form.Row>
		);
	}
}

export default InputFieldStringWidget;
