import loadScript from 'util/loadScript';
import crc32 from 'util/crc32';
import { isAccountant, isAkademi, isErhverv, isPrivat } from 'util/userMethods';
import {
    ADMIN_LOCATION,
    FREE_TAWKTO_ID,
    BUSINESS_TAWKTO_ID,
    SUPPORT_TAWKTO_ID,
    AKADEMI_TAWKTO_ID,
    ACCOUNTANT_TAWKTO_ID
} from 'config/config';
import { formatDate } from './format/DateTime';
import { capitalize } from 'lodash';
import { reportInfo, saveChatEntry } from 'http/api';

const EMBED_LOCATION = 'https://embed.tawk.to';

const getRootUser = user => {
    return user.impersonatorData || user;
};

const makeImpLink = (uid, url) => {
    return `${window.location.origin}/impersonation?impuid=${uid}&redirect=${encodeURIComponent(url)}`;
};

const tawkToState = {
    getAttributes: null,
    loadingPromise: null,
};

const widgetConfigurations = {
    FREE: {
        widgetID: FREE_TAWKTO_ID,
        getAttributes: () => ({})
    },
    PRIVAT: {
        widgetID: SUPPORT_TAWKTO_ID,
        getAttributes: () => {
            return {};
        },
    },
    ERHVERV: {
        widgetID: BUSINESS_TAWKTO_ID,
        getAttributes: user => {
            const rootUser = getRootUser(user);

            return {
                'Cvr': rootUser.cvr,
                'Virksomhedskategori': rootUser.businessCategory,
            };
        },
    },
    REVISOR: {
        widgetID: ACCOUNTANT_TAWKTO_ID,
        getAttributes: user => {
            // extact data regarding the logged in user
            const rootUser = getRootUser(user);

            // extract client data
            let clientDisplayName = null;
            let clientCvr = null;
            let clientCreatedAt = null;

            if (user.impersonation) {
                clientDisplayName = user.displayName;
                clientCvr = user.cvr || null;
                clientCreatedAt = formatDate(user.createdAt);
            }

            return {
                'Cvr': rootUser.cvr || null,
                'Virksomhedskategori': rootUser.businessCategory || null,
                'Klient': clientDisplayName,
                'Klient-cvr': clientCvr,
                'Klient-oprettelse': clientCreatedAt,
            };
        },
    },
    AKADEMI: {
        widgetID: AKADEMI_TAWKTO_ID,
        getAttributes: () => {
            return {};
        },
    },
};

const getConfiguration = user => {
    if(!user) {
        return widgetConfigurations.FREE;
    }

    if (isAkademi(user)) {
        if (user.isEntityAdmin()) {
            // show chat widget to teachers
            return widgetConfigurations.AKADEMI;
        }

        // don't show chat widget to students
        return null;
    }

    if (isAccountant(user)) {
        return widgetConfigurations.REVISOR;
    }

    if (isPrivat(user)) {
        return widgetConfigurations.PRIVAT;
    }

    if (isErhverv(user)) {
        return widgetConfigurations.ERHVERV;
    }

    return widgetConfigurations.ANONYMOUS;
};


const doInitializeWidget = user => {
    // get configuration based on user
    const configuration = getConfiguration(user);
    if (!configuration) {
        return Promise.resolve();
    }

    // init tawk.to
    const scriptLocation = `${EMBED_LOCATION}/${configuration.widgetID}`;
    window.Tawk_LoadStart = new Date();
    window.Tawk_API = window.Tawk_API || {};

    // setup tawk.to event listeners only when the user has logged in
    if(user) {
        const handleChatmessage = async ({ tawkToMessage, agentName }) => {
            const { attachments, message } = tawkToMessage;

            if (message) {
                await saveChatEntry({
                    agentName,
                    message,
                });
            }

            for (const attachment of attachments) {
                const link = attachment.content.file.url;
                saveChatEntry({
                    agentName,
                    link,
                });
            }
        };

        window.Tawk_API.onChatMessageVisitor = function(msg) {
            handleChatmessage({ tawkToMessage: msg });
        };

        window.Tawk_API.onChatMessageAgent = function(msg) {
            const agentName = localStorage.tawkAgent || 'Digital Revisor';

            handleChatmessage({
                agentName,
                tawkToMessage: msg,
            });
        };

        window.Tawk_API.onAgentJoinChat = function({ name }) {
            localStorage.tawkAgent = name;
        };

        window.Tawk_API.onAgentLeaveChat = function() {
            delete localStorage.tawkAgent;
        };
        
        const rootUser = getRootUser(user);
        window.Tawk_API.visitor = {
            name: rootUser.displayName,
            hash: rootUser.id,
        };

        reportInfo('setting tawk visitor data', {
            ...window.Tawk_API.visitor,
        });
    }

    return new Promise(resolve => {
        window.Tawk_API.onLoad = () => {
            tawkToState.getAttributes = configuration.getAttributes;
            resolve();
        };

        loadScript(scriptLocation, { crossorigin: '*' });
    });
};

const getCommonAttributes = user => {
    const rootUser = getRootUser(user);

    const out = {
        // root user data
        'Navn': rootUser.displayName,
        'E-mail': rootUser.email,
        'Oprettelsesdato': formatDate(rootUser.createdAt),
        'Roller': rootUser.roles.join(', '),
        'Admin': `${ADMIN_LOCATION}/accounts/reporting-entities/${rootUser.id}`,

        // relevant products (from client or root user)
        'Produkter': Object.entries(user.ownedProducts).map(([productID, taxYears]) => {
            return capitalize(productID) + ': ' + taxYears.sort().join(', ');
        }).join(' | ') || null,
    };

    return out;
};

const doSetAttributes = attributes => {
    return new Promise((resolve, reject) => {
        window.Tawk_API.setAttributes(attributes, err => {
            if (err) {
                reject(new Error(`failed to set tawk.to attributes: ${err.toString()}`));
                return;
            }

            resolve();
        })
    });
};

const endChat = () => {
    if (typeof window.Tawk_API?.isChatOngoing === 'function' && window.Tawk_API.isChatOngoing()) {
        window.Tawk_API.endChat();
    }
};

const resetTawkToCookies = () => {
    document.cookie = `__tawkuuid=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${window.location.hostname}`;
    document.cookie = '__tawkuuid=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.digitalrevisor.nu';
};

export const showWidget = () => window.Tawk_API?.showWidget();
export const hideWidget = () => typeof window.Tawk_API?.hideWidget === 'function' && window.Tawk_API.hideWidget();

export const shutdownWidget = () => {
    resetTawkToCookies();
    endChat();
    hideWidget();
};

export const initWidget = user => {
    if (!tawkToState.loadingPromise) {
        tawkToState.loadingPromise = doInitializeWidget(user);
    }

    return tawkToState.loadingPromise;
};

export const setUserAttributes = async user => {
    const attributes = {
        ...getCommonAttributes(user),
        ...tawkToState.getAttributes(user),
    };
    
    attributes.hash = window.btoa(crc32(JSON.stringify(attributes)));

    return doSetAttributes(attributes);
};

export const setImpersonationURL = (user, url) => {
    const rootUser = getRootUser(user);

    const impAttributes = {
        'Impersoner': null,
        'Impersoner-klient': null,
    };

    if (isAccountant(rootUser)) {
        if (user.impersonation) {
            impAttributes['Impersoner-klient'] = makeImpLink(user.id, url);
        }
    } else {
        impAttributes['Impersoner'] = makeImpLink(user.id, url);
    }

    return doSetAttributes(impAttributes);
};