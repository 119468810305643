import React from 'react';
import { USE_CUSTOM_DISCOUNT_SYSTEM } from 'flags';
import ColoredText from 'design/atoms/ColoredText';
import withUserData from 'util/withUserData';
import { isAccountant, isAdmin, isHolding } from 'util/userMethods';
import { postResource } from 'network/fetch';
import { erpIdentifiers } from '../';

const ConnectorLink = ({ content, onClick }) => {
    return (
        <ColoredText
            onClick={onClick}
            content={content}
            icon='arrow right'
            iconPosition='left'
            size='large'
            color='green'
            link
        />
    );
};

const ManualConnector = ({ onRegister, userData }) => {
    const doConnect = async (updateContext = {}) => {
        await postResource('erp', {
            service: erpIdentifiers.manual,
        });

        onRegister(updateContext);
    };

    const adminOrAccountant = isAdmin(userData) || isAccountant(userData);

    if (!adminOrAccountant) {
        // regular users
        return (
            <div>
                <p>
                    Bruger du ikke et bogføringssystem, kan vi stadig hjælpe dig med at skabe din årsrapport.
                    Du kan uploade relevante bilag hos os, og så gør vi tallene klar til årsrapporten for dig.
                </p>
                
                {USE_CUSTOM_DISCOUNT_SYSTEM() && (
                    <p>
                        Prisen for opsætningen af årsrapporten er <b>1.000 kr.</b>
                    </p>
                )}

                <ConnectorLink
                    onClick={() => doConnect()}
                    content='Hjælp mig med indtastning'
                />
            </div>
        );
    }

    if (isHolding(userData)) {
        return (
            <div>
                <p>
                    Du kan vælge at indtaste bogføringsdata manuelt i en kassekladde på Digital Revisor,
                    hvor vi udfylder tallene fra sidste år fra den indberettede XBRL. Du kan også vælge at importere bogføringsdata fra en CSV-fil.
                </p>
                <ConnectorLink
                    onClick={() => doConnect()}
                    content='Indtast bogføringsdata manuelt i kassekladde'
                />
                <br />
                <ConnectorLink
                    onClick={() => doConnect({ toggleCsvImporterMode: true })}
                    content='Importer bogføringsdata fra CSV'
                />
            </div>
        );
    }

    return (
        <div>
            <p>
                Bruger du ikke et af ovenstående økonomisystemer,
                kan du vælge at importere bogføringsdata fra en CSV-fil.
            </p>
            <ConnectorLink
                onClick={() => doConnect()}
                content='Importer bogføringsdata fra CSV'
            />
        </div>
    );
};

export default withUserData(ManualConnector);