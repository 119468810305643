import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Header, Icon, Modal, Table } from 'semantic-ui-react';
import { bookMultiple, getJournals } from 'http/erpBroker';
import { formatNumber } from 'util/format/Number';
import { formatDate } from 'util/format/DateTime';
import { extractBookingError } from 'design/pages/Product/components/UiElementAutomatedBooking';
import useUser from 'util/useUser';
import erpSystems from 'util/erpSystems';
import ColoredText from 'design/atoms/ColoredText';
import CSV from 'util/CSV';

const ManualBookingmodal = ({ open, onClose, erpSystemName, prepareBooking, onBookingConfirmed }) => {
    if (!open) {
        return null;
    }

    const handleDownloadCSV = () => {
        const toBook = prepareBooking();

        const csv = new CSV(['Dato', 'Tekst', 'Konto', 'Beloeb']);
        for (const booking of toBook) {
            csv.addToRow('Dato', booking.date);
            csv.addToRow('Tekst', booking.text);
            csv.addToRow('Konto', booking.accountNumber);
            csv.addToRow('Beloeb', booking.amount);
            csv.newLine();
        }

        csv.download('efterposteringer');
    };

    const downloadCSVLink = (
        <ColoredText
            icon='download'
            iconPosition='left'
            content='Download efterposteringer som CSV'
            onClick={handleDownloadCSV}
            link
        />
    );

    return (
        <Modal open onClose={onClose}>
            <Modal.Header>
                Bogføring af efterpostinger
            </Modal.Header>
            <Modal.Content>
                <p>
                    Vi understøtter ikke automatisk bogføring af
                    efterpostinger for dit ERP-system <strong>{erpSystemName}</strong>.
                </p>
                <p>
                    Du bliver derfor nødt til at bogføre efterposteringerne manuelt.
                    Nedenfor kan du downloade alle efterposteringerne som en CSV-fil:
                </p>
                <p>
                    {downloadCSVLink}
                </p>
                <p>
                    Når du har bogført efterposteringerne i <strong>{erpSystemName}</strong>,
                    skal du bekræfte det nedenfor, hvorefter posteringerne vil blive
                    overført til Digital Revisor.
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => onClose()}
                    color='black'
                    content='Annuller'
                    icon='arrow left'
                    labelPosition='left'
                />
                <Button
                    onClick={onBookingConfirmed}
                    labelPosition='left'
                    content='Jeg har bogført efterposteringerne nu'
                    icon='check'
                    primary
                />
            </Modal.Actions>
        </Modal>
    );
};

const PostEntriesBooker = ({ postEntries, archivedPostEntries, fiscalPeriod, draftPostEntriesAndRefetchAccountPlan }) => {
    const [loading, setLoading] = useState(true);
    const [working, setWorking] = useState(false);
    const [viewingArchivedEntries, setViewingArchivedEntries] = useState(false);
    const [justBooked, setJustBooked] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [journals, setJournals] = useState(undefined);
    const [selectedJournalID, setSelelctedJournalID] = useState(undefined);
    const [bookingErrors, setBookingErrors] = useState(null);
    const user = useUser();

    const erpSystem = erpSystems[user.erp];

    useEffect(() => setJustBooked(false), [postEntries]);

    useEffect(() => {
        if (!erpSystem?.allowChoosingJournalForBooking) {
            return setLoading(false);
        }

        getJournals()
            .then(journals => {
                const hasStandardJournal = journals.some(journal => journal.isStandard);
                if (!hasStandardJournal) {
                    setJournals(journals);
                }
            })
            .finally(() => setLoading(false));
    }, [erpSystem]);

    const prepareBooking = () => {
        const toBook = [];

        const taxYearPeriod = `${fiscalPeriod.from} - ${fiscalPeriod.to}`;

        for (const { date, text, affectedAccounts, entryNumber } of postEntries) {
            for (const { amount, accountNumber } of affectedAccounts) {
                toBook.push({
                    date: date.substring(0, 10),
                    text,
                    amount: Number(amount),
                    accountNumber: Number(accountNumber),
                    taxYearPeriod,
                    accountVatCode: 'none',
                    internalEntryNumber: entryNumber,
                });
            }
        }

        return toBook;
    };
    
    const doBook = async () => {
        if (!erpSystem.supportsClosingSheet) {
            setModalOpen(true);
            return;
        }

        const toBook = prepareBooking();

        setBookingErrors(null);
        setWorking(true);

        try {
            await bookMultiple(toBook, selectedJournalID);
            await draftPostEntriesAndRefetchAccountPlan();
            setJustBooked(true);
        } catch (e) {
            const bookingErrors = {
                generalErrorMessages: [],
                errorMessagesByAccountNumber: {},
                allBookingsFailed: false,
            };

            const bookingValidationErrors = e.data;

            if (bookingValidationErrors) {
                const { generalErrorMessages, errorMessagesByAccountNumber, failedEntryNumbers } = bookingValidationErrors;

                bookingErrors.generalErrorMessages = generalErrorMessages;
                bookingErrors.errorMessagesByAccountNumber = errorMessagesByAccountNumber;
                bookingErrors.allBookingsFailed = failedEntryNumbers.length === postEntries.length;

                // it's possible that some entries are succesfully booked, while others are not
                const succeededEntries = postEntries.filter(entry => {
                    return !failedEntryNumbers.includes(entry.entryNumber);
                });

                if (succeededEntries.length > 0) {
                    await draftPostEntriesAndRefetchAccountPlan(succeededEntries.map(entry => entry.entryNumber));
                }
            } else {
                // fallback to old error message system
                const errorToShow = extractBookingError(e.message) || 'Efterposteringerne kunne ikke bogføres';
                bookingErrors.generalErrorMessages.push(errorToShow);
                bookingErrors.allBookingsFailed = true;
            }

            setBookingErrors(bookingErrors);
        }

        setWorking(false);
    };

    const renderEntries = () => {
        const entriesToRender = (
            viewingArchivedEntries
                ? archivedPostEntries
                : postEntries
        );

        if (entriesToRender.length === 0) {
            const message = justBooked ? (
                <span>
                    <Icon name='check circle' color='green' />
                    Efterposteringerne blev bogført!
                </span>
            ) : (
                <i>Der er ingen efterposteringer at bogføre...</i>
            );

            return (
                <Table.Row>
                    <Table.Cell colSpan={4} textAlign='center'>
                        {message}
                    </Table.Cell>
                </Table.Row>
            );
        }

        let errorMessagesByAccountNumber = {};
        if (!viewingArchivedEntries && bookingErrors) {
            errorMessagesByAccountNumber = bookingErrors.errorMessagesByAccountNumber;
        }

        const renderedEntries = [];
        
        for (const { entryNumber, date, text, affectedAccounts } of entriesToRender) {
            for (const accountIndex in affectedAccounts) {
                const { accountNumber, amount } = affectedAccounts[accountIndex];

                const isFirst = accountIndex === '0';
                const cellStyle = { borderTop: isFirst ? undefined : 'none' };

                const associatedErrors = errorMessagesByAccountNumber[accountNumber] || [];

                const renderedEntry = (
                    <Table.Row key={`${entryNumber}#${accountIndex}`} error={associatedErrors.length > 0}>
                        <Table.Cell style={cellStyle}>{formatDate(date)}</Table.Cell>
                        <Table.Cell style={cellStyle}>{text}</Table.Cell>
                        <Table.Cell style={cellStyle}>{accountNumber}</Table.Cell>
                        <Table.Cell style={cellStyle} textAlign='right'>{formatNumber(amount)} kr.</Table.Cell>
                    </Table.Row>
                );

                renderedEntries.push(renderedEntry);

                renderedEntries.push(...associatedErrors.map((errorMessage, i) => {
                    return (
                        <Table.Row key={`${entryNumber}#${accountIndex}:error#${i}`}>
                            <Table.Cell colSpan={4} error style={{ borderTop: 'none' }}>
                                <Icon name='warning sign' /> {errorMessage}
                            </Table.Cell>
                        </Table.Row>
                    );
                }));
            }
        }

        return renderedEntries;
    };

    let errorMessage;
    if (bookingErrors) {
        errorMessage = (
            bookingErrors.allBookingsFailed
                ? 'Efterposteringerne kunne ikke bogføres'
                : 'Ikke alle efterposteringer kunne bogføres'
        );
    }
    
    const bookingTable = (
        <Table size='small' compact='very'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan={5}>
                        <Header
                            content={viewingArchivedEntries ? 'Bogføringslog' : 'Bogføring af efterposteringer'}
                            icon={viewingArchivedEntries ? 'history' : 'book'} 
                        />
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell>Dato</Table.HeaderCell>
                    <Table.HeaderCell>Tekst</Table.HeaderCell>
                    <Table.HeaderCell>Konto</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Beløb</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {renderEntries()}
                {bookingErrors && bookingErrors.generalErrorMessages.map(error => {
                    return (
                        <Table.Row>
                            <Table.Cell colSpan={4} error>
                                <Icon name='warning sign' />
                                {error}
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
            <Table.Footer>
                <Table.Row verticalAlign='middle'>
                    <Table.HeaderCell colSpan={4} verticalAlign='middle'>
                        {archivedPostEntries.length > 0 && (
                            <Button
                                size='small'
                                content={viewingArchivedEntries ? 'Tilbage' : 'Se bogføringslog'}
                                icon={viewingArchivedEntries ? 'arrow left' : 'history'}
                                onClick={() => setViewingArchivedEntries(!viewingArchivedEntries)}
                            />
                        )}
                        
                        {!viewingArchivedEntries && (
                            <div style={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                                {journals && postEntries?.length > 0 && (
                                    <Dropdown
                                        selection
                                        disabled={working}
                                        placeholder='Vælg kassekladde'
                                        style={{ marginRight: '0.5em' }}
                                        onChange={(_, { value }) => setSelelctedJournalID(value)}
                                        defaultValue={selectedJournalID}
                                        options={journals.map(journal => {
                                            return {
                                                text: journal.name,
                                                value: journal.id,
                                            };
                                        })}
                                    />
                                )}

                                {errorMessage && <ColoredText color='red' content={errorMessage} />}

                                <Button
                                    primary
                                    size='small'
                                    icon='book'
                                    content='Bogfør efterposteringer'
                                    floated='right'
                                    onClick={doBook}
                                    disabled={loading || working || !postEntries?.length || (journals && !selectedJournalID)}
                                    loading={loading || working}
                                />
                            </div>
                        )}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );

    return (
        <>
            {bookingTable}
            <ManualBookingmodal
                open={modalOpen}
                erpSystemName={erpSystem?.name}
                onClose={() => setModalOpen(false)}
                prepareBooking={prepareBooking}
                onBookingConfirmed={async () => {
                    setModalOpen(false);
                    setWorking(true);
                    await draftPostEntriesAndRefetchAccountPlan();
                    setJustBooked(true);
                    setWorking(false);
                }}
            />
        </>
    );
};

export default PostEntriesBooker;