import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Divider, Input, Icon, Label, Popup, Loader, Transition } from 'semantic-ui-react';
import { USE_CUSTOM_DISCOUNT_SYSTEM } from 'flags';
import { debounce } from 'lodash';
import { verifyCoupon } from 'http/payment';
import { moms } from 'util/moms';
import AnimatedNumber from 'design/atoms/AnimatedNumber';
import ColoredText from 'design/atoms/ColoredText';
import { doPriceCalculations } from './doPriceCalculations';
import { ShoppingCartSegment } from './ShoppingCart';

const OrderLine = ({ description, price, strong, ...otherProps }) => {
    return (
        <div
            {...otherProps}
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px',
                fontWeight: strong ? 'bold' : undefined,
            }}
        >
            <div
                style={{
                    flex: 1,
                    marginRight: '20px',
                    whiteSpace: 'normal',
                }}
            >
                {description}
            </div>
            <div style={{ whiteSpace: 'nowrap' }}>
                {price}
            </div>
        </div>
    );
};

function CheckoutAmount ({ icon, label, amount, strong, vat, sub, onClear }) {
    const [isHovered, setIsHovered] = useState(false);

    if (vat) {
        amount = moms(amount);
    }

    return (
        <OrderLine
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            strong={strong}
            description={
                <>
                    {isHovered && onClear && (
                        <Popup
                            position='top center'
                            content='Fjern fra kurv'
                            trigger={
                                <Icon
                                    name='x'
                                    color='red'
                                    link
                                    onClick={onClear}
                                />
                            }
                        />
                    )}
                    {icon && <Icon name={icon} />}
                    {label}
                </>
            }
            price={<>{sub && '-'}<AnimatedNumber number={amount} price /> kr.</>}
        />
    );
}

function CheckoutDiscount ({ percentage, amount, description, loading }) {
    const percentageDisplayer = percentage && (
        <ColoredText
            bold
            color='green'
            content={`-${percentage} %`}
        />
    );

    const greenTag = amount && (
        <Icon
            loading={loading}
            name='tag'
            color='green'
        />
    );

    return (
        <CheckoutAmount
            label={<ColoredText>{greenTag}{description || <>Rabat {percentageDisplayer}</>}</ColoredText>}
            amount={amount}
            sub
        />
    );
}

function CouponCode ({ onChange, working, initialCoupon }) {
    const [code, setCode] = useState('');
    const [fetching, setFetching] = useState(false);
    const [valid, setValid] = useState(false);
    const couponRef = useRef();

    const couponChanged = useCallback(async (_, { value }) => {
        setCode(value);

        // begin working
        setFetching(true);

        // fetch coupon
        const coupon = await verifyCoupon(value);

        // update coupon
        if (coupon.valid) {
            setValid(true);
            onChange(coupon);
        } else {
            setValid(false);
            onChange(null);
        }
        
        // stop working
        setFetching(false);
    }, [onChange]);

    useEffect(() => {
        if (!initialCoupon) {
            return;
        }

        couponRef.current.inputRef.current.value = initialCoupon;
        couponChanged(undefined, { value: initialCoupon });
    }, [initialCoupon, couponChanged]); 

    const renderStatusIcon = () => {
        if (!code) {
            return;
        }

        if (fetching) {
            return;
        }
        
        let icon;
        let color;

        if (!valid) {
            icon = 'question';
            color = 'orange';
        } else {
            icon = 'check';
            color = 'green';
        }

        return <Icon
            name={icon}
            color={color}
        />;
    };

    const couponLabel = (
        <Label>
            <Icon name='ticket' />
        </Label>
    );

    return (
        <Input
            ref={couponRef}
            fluid
            placeholder='Indsæt eventuelt rabatkode'
            spellCheck='false'
            disabled={working}
            loading={fetching}
            label={couponLabel}
            labelPosition='left'
            icon={renderStatusIcon()}
            onChange={debounce(couponChanged, 650)}
            style={{ marginBottom: '1em' }}
        />
    );
}

function PaymentOverview({ orderLines, working, proactiveVat, onCouponChange, initialCoupon, hideCouponField, extraContent, discount }) {
    const [coupon, setCoupon] = useState(null);

    const couponChanged = useCallback(newCoupon => {
        onCouponChange(newCoupon);
        setCoupon(newCoupon);
    }, [onCouponChange]);

    const results = doPriceCalculations({
        orderLines,
        proactiveVat,
        coupon,
        discount,
    });

    const cartHasItems = orderLines.length > 0;

    const renderCartItems = () => {
        return orderLines.map(({ icon, description, price, beforeDiscountPrice, discount, discountAmount, onClear }) => {
            // format order line
            let discountLine;
            let displayPrice;
            if (discount) {
                // seperate line for the discount amount
                discountLine = <CheckoutDiscount
                    percentage={discount}
                    amount={proactiveVat ? moms(discountAmount) : discountAmount}
                />;

                // display price before applied discount
                displayPrice = beforeDiscountPrice;
            } else {

                // display regular price
                displayPrice = price;
            }

            return <>
                <CheckoutAmount
                    label={description}
                    icon={icon}
                    amount={proactiveVat ? moms(displayPrice) : displayPrice}
                    onClear={onClear}
                />
                {discountLine}
            </>;
        });
    };

    const workingIndicator = (
        <Transition visible={!!working}>
            <Loader inline active size='small' />
        </Transition>
    );

    return (
        <ShoppingCartSegment title='Indkøbskurv' icon='shopping cart' rightContent={workingIndicator}>
            {renderCartItems()}
            {cartHasItems && <Divider />}
            <CheckoutAmount
                label='Subtotal'
                amount={results.subtotal}
            />
            {coupon && (
                <CheckoutDiscount
                    percentage={coupon.percentOff}
                    description={results.discountDescription}
                    amount={results.discountAmount}
                />
            )}
            {discount?.active && (
                <CheckoutDiscount
                    description={discount.name}
                    amount={discount.purchaseAmountOff}
                />
            )}
            {!proactiveVat && (
                <CheckoutAmount
                    label='Moms' 
                    amount={results.vat}
                />
            )}
            <Divider />
            <CheckoutAmount
                label={`Total ${proactiveVat ? ' (inkl. moms)' : ''}`}
                amount={results.total}
                strong
            />
            {!hideCouponField && !USE_CUSTOM_DISCOUNT_SYSTEM() && (
                <CouponCode
                    onChange={couponChanged}
                    working={working}
                    initialCoupon={initialCoupon || ''}
                />
            )}
            {extraContent}
        </ShoppingCartSegment>
    );
}

export default PaymentOverview;