import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Form, Icon, Input, Modal, Pagination, Table } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import DatePicker from 'design/atoms/DatePicker';
import NumberInput from 'design/atoms/NumberInput';
import { formatDate } from 'util/format/DateTime';
import { formatNumber } from 'util/format/Number';
import { BALANCE, DRIFT } from './accountTypes';
import DeleteIcon from './DeleteIcon';

import styles from './index.module.scss';

const makePostEntryAccount = (number = 0) => {
    return {
        temporaryID: Math.random(),
        amount: 0,
        accountNumber: number,
    };
};

const AccountsPicker = ({ affectedAccounts, onChange, disabled, accountOptions }) => {
    const [mounted, setMounted] = useState(false);
    
    useLayoutEffect(() => {
        setMounted(true);
    }, []);

    const updateAccountAtIndex = (index, propsToMutate = {}) => {
        const copy = [...affectedAccounts];
        copy[index] = { ...copy[index], ...propsToMutate };
        onChange(copy);
    };

    const deleteAtIndex = idx => onChange(affectedAccounts.filter((_, i) => i !== idx));

    return affectedAccounts.map((account, idx) => {
        const isFirst = idx === 0;
        
        return (
            <Form.Group key={account.temporaryID}>
                <Form.Field disabled={disabled} width={8}>
                    {isFirst && <label>Konto</label>}
                    <Dropdown
                        selection
                        search
                        placeholder='Vælg en konto'
                        options={accountOptions}
                        value={account.accountNumber}
                        onChange={(_, { value }) => updateAccountAtIndex(idx, { accountNumber: value })}
                        disabled={isFirst}
                    />
                </Form.Field>
                <Form.Field disabled={disabled} width={7}>
                    {isFirst && <label>Beløb</label>}
                    <NumberInput
                        autoFocus={mounted ? !isFirst : isFirst}
                        className={styles.numberInput}
                        onChange={amount => updateAccountAtIndex(idx, { amount })}
                    />
                </Form.Field>
                <Form.Field width={1}>
                    {isFirst && <label>&nbsp;</label>}
                    <Button
                        onClick={() => !isFirst && deleteAtIndex(idx)}
                        style={{
                            pointerEvents: isFirst ? 'none' : undefined,
                            opacity: isFirst ? 0.5 : 1,
                        }}
                        icon='trash'
                        basic
                        circular
                        tabIndex={-1}
                    />
                </Form.Field>
            </Form.Group>
        );
    });
};

const CreatePostEntryForm = ({ fiscalPeriod, onClose, addPostEntry, accountNumber, accountplan }) => {
    const [fiscalStart, fiscalEnd] = [fiscalPeriod.start, fiscalPeriod.end].map(({ year, month, day }) => {
        return Date.UTC(year, month - 1, day);
    });

    const [date, setDate] = useState(fiscalEnd); 
    const [text, setText] = useState(`OMP afslutningsark ${fiscalPeriod.taxYear}`); 
    const [affectedAccounts, setAffectedAccounts] = useState([
        makePostEntryAccount(accountNumber),
        makePostEntryAccount(),
    ]);

    const [working, setWorking] = useState(false);

    const accountOptions = useMemo(() => {
        return accountplan
            .filter(({ accountType }) => {
                return accountType === DRIFT || accountType === BALANCE;
            })
            .map(({ number, text }) => {
                return {
                    text: `${number} - ${text}`,
                    key: number,
                    value: number,
                };
            });
    }, [accountplan]);

    const sumOfAccounts = affectedAccounts.reduce((acc, { amount }) => acc += amount || 0, 0);

    const validateEntry = () => {
        if (!date) return 'Vælg en dato';
        if (!text) return 'Indtast en tekst';

        if (affectedAccounts.length < 2) return 'Vælg mindst to konti';

        const seenAccounts = new Set();
        for (let accIdx = 0; accIdx < affectedAccounts.length; accIdx++) {
            const { amount, accountNumber } = affectedAccounts[accIdx];

            if (!amount)                         return `Indtast beløb for linje #${accIdx + 1}`;
            if (amount % 1 !== 0)                return `Beløbet for konto ${accountNumber} skal være et heltal`;
            if (!accountNumber)                  return `Vælg konto for linje #${accIdx + 1}`;
            if (seenAccounts.has(accountNumber)) return `Konto ${accountNumber} er valgt mere end 1 gang`;

            seenAccounts.add(accountNumber);
        }

        if (sumOfAccounts !== 0) {
            return 'Beløbssum skal give 0';
        }

        return null;
    };

    const createPostEntry = async () => {
        const validationError = validateEntry();
        if (validationError) {
            return toast.error(validationError);
        }

        setWorking(true);
        await addPostEntry({
            date: new Date(date).toISOString(),
            text,
            affectedAccounts,
        });
        setWorking(false);
        onClose();
    };

    return (
        <>
            <Modal.Content>
                <Form>
                    <Form.Group widths={2}>
                        <Form.Field disabled={working}>
                            <label>Dato</label>
                            <DatePicker
                                onChange={value => setDate(value + (1000 * 60 * 60 * 5))}
                                value={date}
                                minDate={fiscalStart}
                                maxDate={fiscalEnd}
                                placeholder='Vælg dato'
                            />
                        </Form.Field>
                        <Form.Field disabled={working}>
                            <label>Tekst</label>
                            <Input
                                onChange={(_, { value }) => setText(value)}
                                placeholder='Indtast tekst'
                                defaultValue={text}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions style={{ background: 'white', textAlign: 'left' }}>
                <Form style={{ margin: '0.5em' }}>
                    <AccountsPicker
                        affectedAccounts={affectedAccounts}
                        onChange={affectedAccounts => setAffectedAccounts(affectedAccounts)}
                        accountOptions={accountOptions}
                        disabled={working}
                    />
                    <Form.Field style={{ textAlign: 'right' }}>
                        <Button
                            fluid
                            basic
                            icon='plus'
                            content='Tilføj linje'
                            onClick={() => setAffectedAccounts([...affectedAccounts, makePostEntryAccount()])}
                        />
                    </Form.Field>
                    {affectedAccounts.some(acc => acc.amount !== 0) && (
                        <Form.Field style={{ textAlign: 'right' }}>
                            {sumOfAccounts === 0 && <Icon name='check circle' color='green' />}
                            Sum: {formatNumber(sumOfAccounts)} kr.
                        </Form.Field>
                    )}
                </Form>
            </Modal.Actions>
            <Modal.Actions>
                <Button
                    disabled={working}
                    content='Annuller'
                    color='black'
                    onClick={onClose}
                />
                <Button
                    primary
                    disabled={working}
                    loading={working}
                    content='Opret efterpostering'
                    icon='plus'
                    onClick={() => createPostEntry()}
                />
            </Modal.Actions>
        </>
    );
};

const tableHeaders = [
    {
        text: 'Bilagsnummer',
        property: 'entryNumber',
    },
    {
        text: 'Dato',
        property: 'date',
    },
    {
        text: 'Momskode',
        property: 'vatCode',
    },
    {
        text: 'Tekst',
        property: 'text',
    },
    {
        text: 'Beløb',
        property: 'amount',
        style: { textAlign: 'right' },
    },
    {
        style: { textAlign: 'right' },
    },
];

const EntriesViewer = ({ fiscalPeriod, accountNumber, entriesFetcher, accountPostEntries, addPostEntry, accountplan, deletePostEntriesByNumber }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [creatingPostEntry, setCreatingPostEntry] = useState(false);
    const [entries, setEntries] = useState([]);
    const [sortBy, setSortBy] = useState(tableHeaders[0].property);
    const [sortDirection, setSortDirection] = useState(1);
    const [page, setPage] = useState(0);

    useEffect(() => {
        const fetchEntries = async () => {
            try {
                const entries = await entriesFetcher(accountNumber);
                setEntries(entries)
            } catch (e) {
                const errorCode = e.data?.internalErrorCode;

                const knownErrorCodes = {
                    'fiscal_period_mismatch': 'Der er uoverensstemmelse mellem regnskabsperioden, der er registreret i CVR og i bogføringssystemet',
                };

                setError(knownErrorCodes[errorCode] || 'Der opstod en fejl');
            }
            setLoading(false);
        };

        fetchEntries();
    }, [accountNumber, entriesFetcher]);

    const sortColumnClicked = column => {
        if (sortBy === column) {
            setSortDirection(sortDirection * -1);
        } else {
            setSortBy(column);
            setSortDirection(1);
        }
    };

    const sortEntries = useCallback(entries => {
        return [...entries].sort((a, b) => {
            const aVal = a[sortBy];
            const bVal = b[sortBy];

            let result;

            if (typeof aVal === 'string') {
                result = aVal.localeCompare(bVal);
            } else {
                result = aVal - bVal;
            }

            return result * sortDirection;
        });
    }, [sortBy, sortDirection]);

    const sortedBookkeepingEntires = useMemo(() => sortEntries(entries), [entries, sortEntries]);

    const sortedPostEntries = useMemo(() => sortEntries(accountPostEntries), [accountPostEntries, sortEntries]);

    const renderEntry = ({ entryNumber, date, vatCode, text, amount }, isPostEntry = false) => {
        return (
            <Table.Row>
                <Table.Cell>{isPostEntry ? '-' : entryNumber}</Table.Cell>
                <Table.Cell>{formatDate(date)}</Table.Cell>
                <Table.Cell>{vatCode?.trim()}</Table.Cell>
                <Table.Cell>{text}</Table.Cell>
                <Table.Cell textAlign='right'>{formatNumber(amount)}</Table.Cell>
                <Table.Cell textAlign='right'>
                    {isPostEntry && (
                        <DeleteIcon
                            text='Slet efterpostering'
                            onDelete={() => deletePostEntriesByNumber(entryNumber)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
        );
    };

    const renderHeaderRow = text => {
        return (
            <Table.Row>
                <Table.Cell colSpan={6} >
                    <strong>{text}</strong>
                </Table.Cell>
            </Table.Row>
        );
    };

    if (loading) {
        return (
            <Modal.Content>
                <strong>
                    <Icon name='spinner' loading />
                    Indlæser posteringer...
                </strong>
            </Modal.Content>
        );
    }

    if (error) {
        return <Modal.Content>
            <strong>
                <Icon name='warning sign' color='red' /> Posteringer kunne ikke hentes:
            </strong>
            <div>
                {error}
            </div>
        </Modal.Content>;
    }

    if (creatingPostEntry) {
        return (
            <CreatePostEntryForm
                onClose={() => setCreatingPostEntry(false)}
                addPostEntry={addPostEntry}
                accountNumber={accountNumber}
                fiscalPeriod={fiscalPeriod}
                accountplan={accountplan}
            />
        );
    }

    const pageSize = 10;
    const totalPages = Math.ceil(entries.length / pageSize);
    const bookkeepingEntriesToShow = sortedBookkeepingEntires.slice(page * pageSize, (page * pageSize) + pageSize);

    const table = (
        <Table basic='very' compact='very'>
            <Table.Header>
                <Table.Row>
                    {tableHeaders.map(({ text, property, ...props }) => {
                        const isSelectedForSorting = sortBy === property;

                        return (
                            <Table.HeaderCell
                                style={{ cursor: 'pointer' }}
                                onClick={() => property && sortColumnClicked(property)}
                                content={
                                    <span>
                                        {isSelectedForSorting && <Icon name={sortDirection === -1 ? 'caret down' : 'caret up'} />}
                                        {text}
                                    </span>
                                }
                                {...props}
                            />
                        );
                    })}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {accountPostEntries?.length > 0 && renderHeaderRow('Efterposteringer')}
                {sortedPostEntries.map(entry => renderEntry(entry, true))}

                {entries?.length > 0 && renderHeaderRow('Bogførte posteringer')}
                {bookkeepingEntriesToShow.map(entry => renderEntry(entry, false))}
                {totalPages > 1 && (
                    <Table.Row>
                        <Table.Cell colSpan={6} textAlign='center'>
                            <Pagination
                                activePage={page + 1}
                                totalPages={totalPages}
                                onPageChange={(_, { activePage }) => setPage(activePage - 1)}
                            />
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );

    return (
        <>
            <Modal.Content scrolling>
                {table}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    icon='plus'
                    content='Ny efterpostering'
                    onClick={() => setCreatingPostEntry(true)}
                />
            </Modal.Actions>
        </>
    );
};

export default EntriesViewer;