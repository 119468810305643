import React, { useEffect, useState } from 'react';
import { Transition } from 'semantic-ui-react';

const MountAnimation = ({ wrap, animation = 'fade up', duration = 150, children, delay, disabled }) => {
    const [isMounted, setIsMounted] = useState(false);
    const [mounting, setMounting] = useState(false);

    useEffect(() => {
        if (isMounted || mounting) {
            return;
        }

        if (delay) {
            setMounting(true);
            setTimeout(() => setIsMounted(true), delay);
        } else {
            setIsMounted(true);
        }
    }, [delay, mounting, isMounted]);

    const getChildren = () => {
        if (wrap) {
            return <div>{children}</div>;
        }
        return children;
    };

    if (disabled) {
        return getChildren();
    }

    return <Transition.Group animation={animation} duration={duration}>
        {isMounted && getChildren()}
    </Transition.Group>;
};

export default MountAnimation;