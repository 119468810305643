import { getIconFromExtension } from './iconExtensions';
import crc32 from '../crc32';

/**
 * Creates a file (in reality an augmented Blob)
 * Handles "File" constructor not being implemented
 * @param {BlobPart[]} data 
 * @param {string} name 
 * @param {string} type
 * @returns {File}
 */
function createFile(data, name, type) {
    const out = new Blob(data, { type });
    const now = new Date();
    out.name = name;
    out.lastModified = now.getTime();
    out.lastModifiedDate = now;
    out.webkitRelativePath = '';
    return out;
}

export function readAsText(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
    });
}

export function readCP437AsText(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file, 'l2');
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
    });
}

export function fileChecksum(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onerror = reject;
        reader.onload = () => {
            const { result } = reader;
            resolve(crc32(result));
        };
    });
}

export function readAsImage(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const { result } = reader;
            const img = new Image();
            img.src = result;
            img.onload = () => resolve(img);
            img.onerror = reject;
        };
        reader.onerror = reject;
    });
}

export async function base64ToFile(data, fileName, mimeType) {
    const urlRegexp = /^data:(.+?);base64,(.+)$/g;
    let match;
    if ((match = urlRegexp.exec(data))) {
        mimeType = match[1];
        data = match[2];
    } else if (!mimeType) {
        mimeType = 'application/octet-stream';
    }
    const blob = await base64ToBlob(data, mimeType);
    return createFile([blob], fileName, mimeType);
}

export function base64ToBlob(b64, contentType = 'application/octet-stream') {
    const decoded = atob(b64);
    const bytes = new Uint8Array(decoded.length);
    for (let i = 0; i < decoded.length; i++) {
        bytes[i] = decoded.charCodeAt(i);
    }
    return new Blob([ bytes ], { type: contentType });
}

export function base64ToImage(b64) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = b64;
        img.onerror = reject;
        img.onload = () => resolve(img);
    });
}

export function isFile(thing) {
    if (!thing) {
        return false;
    }
    return thing.constructor === File;
}

/**
 * @param {File} fileA 
 * @param {File} fileB 
 */
export function compareFiles(fileA, fileB) {
    if (!isFile(fileA) || !isFile(fileB)) {
        return false;
    }
    const compareableFile = file => JSON.stringify({
        name: file.name,
        lastModified: file.lastModified,
        size: file.size,
    });
    return compareableFile(fileA) === compareableFile(fileB);
}

export function getFileExtension(file) {
    const fname = file?.name;
    if (!fname) {
        return '';
    }

    const lidxdot = fname.lastIndexOf('.');
    if (lidxdot === -1) {
        return '';
    }

    return fname.substr(lidxdot + 1).toLowerCase();
}

export function getFileIcon(file, outline = false) {
    const ext = getFileExtension(file);
    const icon = getIconFromExtension(ext, outline);

    return icon;
}

export function formatFileSize({ size }) {
    if (size < 1000) {
        return `${size} B`;
    }

    if (size < 1000000) {
        return `${(size / 1000).toFixed(2)} KB`;
    }

    return `${(size / 1000000).toFixed(2)} MB`;
}