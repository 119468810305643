import React from 'react';
import MountAnimation from 'design/atoms/MountAnimation';
import styles from './styles.module.scss';

function StatusMessage ({ children }) {
    if (!children) {
        return null;
    }

    return <MountAnimation>
        <div className={styles.container}>
            {children}
        </div>
    </MountAnimation>;
}

export default StatusMessage;