import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Table, Icon, List, Grid, Loader, Message, Transition, Button, Header, Divider } from 'semantic-ui-react';
import { unregisterERP } from 'http/erpBroker';
import { getFileMetadata, uploadFile, deleteFile } from 'http/file-storage';
import { setHoldingFiles, createHoldingTask, getHoldingTask } from 'http/internalTasks';
import { formatFileSize, getFileIcon, fileChecksum, getFileExtension } from 'util/files';
import ColoredText from 'design/atoms/ColoredText';
import useRestResource from 'util/useRestResource';
import { MANUEL_HOLDING_REVIEW_PRODUCT_ID } from 'design/pages/Payment/PaymentFlowManualHoldingReview';
import { getAddonProductByID, getAddonProductPurchases } from 'http/payment';
import { moms } from 'util/moms';
import { formatNumber } from 'util/format/Number';
import { USE_CUSTOM_DISCOUNT_SYSTEM } from 'flags';

const FileUploadedMessage = () => (
    <span>
        <Icon name='check circle' />
        <p>En medarbejder kigger bilaget igennem snarest muligt.</p>
    </span>
);

const RequirementsInfo = () => {
    return (
        <div>
            <p>
                For at vi kan hjælpe dig, skal du uploade relevante bilag, der viser,
                hvilke aktiviteter, der har været i dit holdingselskab.
            </p>
            <p>
                Upload følgende dokumenter:
            </p>
            <List bulleted>
                <List.Item>Bankkontoudtog</List.Item>
                <List.Item>Stiftelsesdokument</List.Item>
                <List.Item>Køb af datterselskab</List.Item>
            </List>
        </div>
    );
};

const FileUploadTable = ({ files, onFileSelected, onFileRemoved, disabled }) => {
    const hiddenUploadField = useRef();

    const uploadClicked = () => {
        if (!hiddenUploadField?.current) {
            return;
        }

        hiddenUploadField.current.click();
    };

    const onFileChosen = async e => {
        const file = e.target.files && e.target.files[0];
        if (!file) {
            return;
        }

        const checksum = await fileChecksum(file);
        if (files.find(f => f.checksum === checksum)) {
            toast.warn('Du har allerede vedhæftet valgte fil');
            return;
        }

        file.checksum = checksum;
        onFileSelected(file);
        
        // deselect file in hidden fileupload field
        e.target.value = '';
    };

    const removeFile = idx => {
        onFileRemoved(idx);
    };

    const renderTableHeader = () => {
        if (files.length === 0) {
            return null;
        }

        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        Navn
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Størrelse
                    </Table.HeaderCell>
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
        );
    };

    const renderTableBody = () => {
        let content;

        if (files.length === 0) {
            content = <Table.Row>
                <Table.Cell>Du har ikke uploadet nogen bilag endnu</Table.Cell>
            </Table.Row>;
        } else {
            content = files.map((file, i) => (
                <Table.Row key={file.id || file.checksum}>
                    <Table.Cell>
                        <Icon name={getFileIcon(file, true)} />
                        {file.name}
                    </Table.Cell>
                    <Table.Cell>{formatFileSize(file)}</Table.Cell>
                    <Table.Cell textAlign='right'>
                        <Icon
                            name='x'
                            color='black'
                            onClick={() => removeFile(i)}
                            link={!disabled}
                            disabled={disabled}
                        />
                    </Table.Cell>
                </Table.Row>
            ));
        }

        return <Table.Body>{content}</Table.Body>;
    };

    return (
        <Table>
            {renderTableHeader()}
            {renderTableBody()}
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell textAlign='right' colspan='3'>
                        <input
                            type='file'
                            style={{ display: 'none' }}
                            ref={hiddenUploadField}
                            onChange={onFileChosen}
                        />
                        <ColoredText
                            link
                            content='Upload nyt bilag'
                            icon='upload'
                            iconPosition='left'
                            onClick={uploadClicked}
                            disabled={disabled}
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
};

const BookingMaster = ({ taxYear, pagePrefix }) => {
    const [loading, setLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [task, setTask] = useState(null);
    const [working, setWorking] = useState();

    const doFilesUpdate = (files) => {
        if (task) {
            task.done = false;
        }
        setFiles(files);
    };

    // load task on mount
    useEffect(() => {
        const loadTask = async () => {
            const htask = await getHoldingTask(taxYear);
            setTask(htask);
            setLoading(false);
        };
        loadTask();
    }, [taxYear]);

    // when task is loaded, fetch task files metadata
    useEffect(() => {
        if (!task) {
            return;
        }

        const getTaskFiles = async () => {
            setLoading(true);

            // fetch metadata for each task file
            const files = await Promise.all(task.files.map(fileID => {
                return getFileMetadata(fileID);
            }));
            
            setFiles([...files]);

            setLoading(false);
        };

        getTaskFiles();
    }, [task]);

    const onFileSelected = async file => {
        if (task) {
            try {
                // try upload file
                const fileID = await uploadFile(file);
                file.id = fileID;
            } catch (e) {
                switch (e.status) {
                    // file type not allowed
                    case 403:
                        toast.error(`Filtype ikke tilladt (.${getFileExtension(file)})`);
                        return;

                    // file is too big
                    case 413:
                        toast.error('Filen er for stor (maks 4 MB)');
                        return;

                    // unknown error
                    default:
                        toast.error('Der opstod en ukendt fejl');
                        return;
                }
            }

            // update file state serverside
            await setHoldingFiles(taxYear, [...files.map(f => f.id), file.id]);
            toast.success(<FileUploadedMessage />);
        }

        // update file table locally
        doFilesUpdate([...files, file]);
    };

    const onFileRemoved = async fidx => {
        const fileID = files[fidx].id;

        // remove file
        files.splice(fidx, 1);

        // if there is a task, update file state serverside
        if (task) {
            await Promise.all([
                // delete file serverside
                deleteFile(fileID),

                // update task files
                setHoldingFiles(taxYear, [...files.map(f => f.id)]),
            ]);
        }

        doFilesUpdate([...files]);
    };

    const orderReview = async () => {
        // ensure attachments
        if (files.length === 0) {
            toast.warn('Upload mindst 1 bilag');
            return;
        }

        setWorking(true);

        try {
            // create product link
            const link = `${pagePrefix}/index`;

            // upload files
            const fileIdentifiers = await Promise.all(files.map(file => {
                return uploadFile(file);
            }));
            
            // create internal task
            const ntask = await createHoldingTask(taxYear, fileIdentifiers, link);

            // set task => trigger scene change
            setTask(ntask);

            toast.success('Din bestilling blev oprettet');
        } catch {
            toast.error('Der opstod en fejl og gennemgangen kunne ikke bestilles');
        }

        setWorking(false);
    };

    const renderTaskStatus = () => {
        if (!task) {
            return null;
        }

        if (task.done) {
            return (
                <Message
                    icon='check circle'
                    color='green'
                    header='Du er klar til at færddiggøre din årsrapport!'
                    content={
                        <span>
                            En medarbejder har gennemgået bilagene og gjort tallene klar til din årsrapport.
                        </span>
                    }
                />
            );
        }
        
        return (
            <Message
                icon='tasks'
                color='blue'
                header='Vi klargør dine tal hurtigst muligt'
                content={
                    <span>
                        En medarbejder vil kigge dine bilag igennem snarest muligt.
                        Du vil modtage en mail, når tallene er gjort klar til årsrapporten.
                    </span>
                }
            />
        );
    };

    const renderOptions = () => {
        return (
            <Transition visible={!task}>
                <Grid columns={2}>
                    <Grid.Column textAlign='left'>
                        <ColoredText
                            link
                            size='large'
                            color='black'
                            content='Annuller'
                            icon='arrow left'
                            iconPosition='left'
                            onClick={unregisterERP}
                            disabled={working}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        <Button
                            color='green'
                            content='Bestil gennemgang'
                            icon='check circle'
                            labelPosition='right'
                            onClick={orderReview}
                            disabled={working}
                            loading={working}
                        />
                    </Grid.Column>
                </Grid>
            </Transition>
        );
    };

    const renderBookingForm = () => {
        return (
            <div>
                {renderTaskStatus()}
                <RequirementsInfo />
                <FileUploadTable
                    files={files}
                    onFileSelected={onFileSelected}
                    onFileRemoved={onFileRemoved}
                    disabled={working}
                />
                {renderOptions()}
            </div>
        );
    };

    if (loading) {
        return <Loader inline='centered' active />;
    }

    return (
        <div>
            {renderBookingForm()}
        </div>
    );
};

const fetchManualReviewProductInfo = async () => {
    const [manualReviewProduct, manualProductReviewPurchases] = await Promise.all([
        getAddonProductByID(MANUEL_HOLDING_REVIEW_PRODUCT_ID),
        getAddonProductPurchases(MANUEL_HOLDING_REVIEW_PRODUCT_ID),
    ]);

    const hasAccessToManualReview = manualProductReviewPurchases.length > 0;

    return { manualReviewProduct, hasAccessToManualReview };
};

const ManualReviewBooker = props => {
    const { loading, error, data } = useRestResource({ fetcher: fetchManualReviewProductInfo });

    if (loading) {
        return <Loader inline='centered' active />;
    }

    if (error) {
        return <Message color='red'><Icon name='warning sign' /> Der opstod en fejl</Message>;
    }

    const { manualReviewProduct, hasAccessToManualReview } = data;

    if (hasAccessToManualReview) {
        return <BookingMaster {...props} />;
    }

    const price = moms(manualReviewProduct.price);
    const priceLabel = `${formatNumber(price)} kr.`;

    const { pathname, search, hash } = window.location;
    const redirectPath = pathname + search + hash;
    const afterPurchaseRedirectParam = `?redirect=${encodeURIComponent(redirectPath)}`;

    return (
        <div>
            <Header>{manualReviewProduct.name}</Header>
            {manualReviewProduct.description}
            <RequirementsInfo />
            <br />
            <ColoredText content='Annuller' link icon='arrow left' iconPosition='left' onClick={unregisterERP} />
            <Divider />
            <div style={{ textAlign: 'center' }}>
                <Link to={'/betaling/manuel-gennemgang' + afterPurchaseRedirectParam}>
                    <Button content='Køb manuel gennemgang' primary label={priceLabel} />
                </Link>
            </div>
        </div>
    );
};

export default props => {
    return USE_CUSTOM_DISCOUNT_SYSTEM()
        ? <ManualReviewBooker {...props} />
        : <BookingMaster {...props} />;
};