import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useState } from 'react';
import { Divider } from 'semantic-ui-react';

import HintWrapper from './hint_wrapper/HintWrapper';
import HintWrapperModel from './hint_wrapper/HintWrapperModel';

import { Timer } from 'easytimer.js';

import HintWrapperSources from './hint_wrapper/HintWrapperSourceTypes';
import { HintTypes } from './hint/HintTypes';
import style from './HintSection.module.scss';
import { ProductElementInteractionDataTypes } from 'design/pages/Product/field_interactivity/ProductElementInteractionData';
import ProductSections from './util/ProductSections';

const HintSection = forwardRef((props, ref) => {
    const {
        productMetadata,
        productData,
        sectionFieldWrappers,
        hintSectionVisibility,
        currentSectionSlug,
        factsBeingUpdated,
        hasPaid,
        productPageStyle,
        children
    } = props;

    // ==================================================
    
    const [sectionMetadataHintWrappers, setSectionMetadataHintWrappers] = useState([]);
    const [fieldVideoHintWrapper, setFieldVideoHintWrapper] = useState();
    const [fieldBasicHintWrapper, setFieldBasicHintWrapper] = useState();
    
    // Custom hint props passed to hints with different types
    const [customHintProps, setCustomHintProps] = useState({});
    const updateCustomHintProps = (fn) => {
        const customHintPropsCopy = {...customHintProps};
        const updatedCustomHintProps = fn(customHintPropsCopy);
        setCustomHintProps(updatedCustomHintProps);
    };

    const [hintsContainerMarginTop, setHintsContainerMarginTop] = useState(0);
    
    // ==================================================

    const timer = new Timer();
    const SECONDS_TO_TRIGGER_HINT = 1;

    // --------------------------------------------------

    useEffect(() => {
        onSectionSelected();
    }, [currentSectionSlug]);

    useLayoutEffect(() => {
        /**
         * Since product page header has position absolute and is drawn on top of everything else, we cannot position the hints container at `top: 0`.
         * There must be some margin from the top that we have to calculate.
         */
        const productPageHeader = document.getElementById('product-page__header');

        let headerHeight = 0;
        if (productPageHeader) {
            headerHeight = productPageHeader.getClientRects()[0].height;
        }

        setHintsContainerMarginTop(`calc(${headerHeight}px + ${productPageStyle.paddingTop})`);
    }, [productPageStyle.paddingTop]);

    // --------------------------------------------------

    const onSectionSelected = () => {
        const sectionHintWrappers = _getSectionHintWrappers();
        setSectionMetadataHintWrappers(sectionHintWrappers);

        if(hintSectionVisibility?.fieldsContainHints) {
            [
                setFieldBasicHintWrapper,
                setFieldVideoHintWrapper
            ].forEach(fn => fn(new HintWrapperModel(null, HintWrapperSources.FIELD)));
        } else {
            [
                setFieldBasicHintWrapper,
                setFieldVideoHintWrapper
            ].forEach(fn => fn(null));
        }

        // ==================================================

        // reset custom props for hint type VideosErp
        updateCustomHintProps(customHintPropsCopy => {
            customHintPropsCopy[HintTypes.VIDEOS_ERP] = null;
            return customHintPropsCopy;
        });
    };

    // --------------------------------------------------

    const onProductElementInteractionStart = productElementInteraction => {
        const { interactionData } = productElementInteraction;
        const { type: interactionType, customData } = interactionData;
        const {
            FIELD_WRAPPER,
            FOLDABLE_SEGMENT_PANEL
        } = ProductElementInteractionDataTypes;

        switch(interactionType) {
            case FIELD_WRAPPER: {
                // Assume the interactionData.customData is a product field
                const field = customData;

                if(field.type === 'group') {
                    return;
                }

                if(!field.isAssociatedWithHint || !field.hint) {
                    return;
                }

                timer.start();
                timer.addEventListener('secondsUpdated', event => {
                    const timer = event.detail.timer;
                    const { seconds } = timer.getTimeValues();
                    if(seconds >= SECONDS_TO_TRIGGER_HINT) {
                        const fieldHintWrapper = new HintWrapperModel(field.hint, HintWrapperSources.FIELD);
                        if(field.hint.type === HintTypes.BASIC) {
                            setFieldBasicHintWrapper(fieldHintWrapper);
                        } else if(field.hint.type === HintTypes.VIDEO) {
                            setFieldVideoHintWrapper(fieldHintWrapper);
                        }

                        timer.stop();
                        timer.removeAllEventListeners();
                    };
                });
                break;
            }
            case FOLDABLE_SEGMENT_PANEL: {
                break;
            }
        }        
    };
    
    const onProductElementInteractionEnd = productElementInteraction => {
        const { interactionData, event } = productElementInteraction;
        const { type: interactionType, interactionContext, customData } = interactionData;
        const {
            FIELD_WRAPPER,
            FOLDABLE_SEGMENT_PANEL
        } = ProductElementInteractionDataTypes;

        // ==================================================

        switch(interactionType) {
            case FIELD_WRAPPER: {
                // Assume the interactionData.customData is a product field
                const field = customData;

                // =========================

                if(field.type === 'group') {
                    return;
                }

                if(!field.isAssociatedWithHint || !field.hint) {
                    return;
                }
                
                if(timer.isRunning) {
                    timer.stop();
                    timer.removeAllEventListeners();
                }

                // Hide the hint
                if(fieldBasicHintWrapper.hint && field.hint.type === HintTypes.BASIC) {
                    setFieldBasicHintWrapper(new HintWrapperModel(null, HintWrapperSources.FIELD));
                }
                break;
            } case FOLDABLE_SEGMENT_PANEL: {
                if(interactionContext.section === ProductSections.ERP_CONNECTION) {
                    if(event.type === 'click') {
                        const { isOpen } = customData;
                        if(isOpen) {
                            updateCustomHintProps(customHintPropsCopy => {
                                customHintPropsCopy[HintTypes.VIDEOS_ERP] = null;
                                return customHintPropsCopy;
                            });
                            return;
                        }

                        const hintWrapper_VideosErp = _getSectionHintWrappers().find(sectionHint => sectionHint.hint.type === HintTypes.VIDEOS_ERP);
                        if(!hintWrapper_VideosErp) {
                            return;
                        }
                        
                        updateCustomHintProps(customHintPropsCopy => {
                            customHintPropsCopy[HintTypes.VIDEOS_ERP] = {
                                selectedErp: interactionContext.erpSystemID
                            };
                            return customHintPropsCopy;
                        });
                    }
                }
                break;
            }
        }
    };

    useImperativeHandle(ref, () => ({
        onProductElementInteractionStart,
        onProductElementInteractionEnd
    }));

    // --------------------------------------------------

    const _getSectionHintWrappers = () => {
        const { sections } = productMetadata;
        const activeSection = sections.find(section => section.slug === currentSectionSlug);
        const {
            isAssociatedWithHint,
            hint
        } = activeSection;

        // --------------------------------------------------
        
        const sectionHintWrappers = [];
        if(isAssociatedWithHint) {
            const hintWrapper = new HintWrapperModel(hint, HintWrapperSources.SECTION);
            sectionHintWrappers.push(hintWrapper);
        }
       
        return sectionHintWrappers;
    };

    // --------------------------------------------------

    const isEmpty = () => {
        if(!hintSectionVisibility) {
            return true;
        }
        const { sectionContainsHints, fieldsContainHints } = hintSectionVisibility;
        return !sectionContainsHints && !fieldsContainHints;
    };

    // -------------------------

    const aggregateSectionHints = () => {
        return [...sectionMetadataHintWrappers];
    };

    const aggregateFieldHints = () => {
        const result = [];
        if(fieldVideoHintWrapper) {
            result.push(fieldVideoHintWrapper);
        }
        if(fieldBasicHintWrapper) {
            result.push(fieldBasicHintWrapper);
        }

        return result;
    };

    // --------------------------------------------------

    const renderHintWrapperPlaceholder = text => {
        const placeholder = (
            <div className={style['empty-hint-container-content']}>
                <p>{text}</p>
            </div>
        );

        return placeholder;
    };

    const renderHintWrapper = hintWrapper => {
        let altContent = null;
        const showFieldEmptyHintContainer = hintWrapper.source === HintWrapperSources.FIELD && !hintWrapper.hint;
        if(showFieldEmptyHintContainer) {
            if(hintWrapper === fieldBasicHintWrapper) {
                altContent = renderHintWrapperPlaceholder(`Her vises basic hints som er forbundet med felter`);
            } else if(hintWrapper === fieldVideoHintWrapper) {
                altContent = renderHintWrapperPlaceholder(`Her vises video hints som er forbundet med felter`);
            }
        }

        // ==================================================

        return <HintWrapper
            sectionFieldWrappers={sectionFieldWrappers}
            productData={productData}
            productMetadata={productMetadata}
            hasPaid={hasPaid}
            factsBeingUpdated={factsBeingUpdated}
            hintWrapper={hintWrapper}
            altContent={altContent}
            customHintProps={customHintProps[hintWrapper.hint?.type] || {}}
        />;
    };

    const renderHints = () => {
        const sectionHints = aggregateSectionHints().map(x => renderHintWrapper(x));
        const fieldHints = aggregateFieldHints().map(x => renderHintWrapper(x));

        return (
            <>
                {sectionHints}
                {(sectionHints.length * fieldHints.length !== 0) && <Divider />}
                {fieldHints}
            </>
        );
    };

    // Do not render hint section if we have nothing to show.
    if(isEmpty()) {
        return children;
    }

    // --------------------------------------------------

    const hintsContainerStyle = {
        top: hintsContainerMarginTop
    };

    return (
        <div className={style['hint-section']}>
            <div className={style['hints-container']} style={hintsContainerStyle}>
                {renderHints()}
            </div>
            <div className={style['children-container']}>
                {children}
            </div>
        </div>
    );
});

export default HintSection;
