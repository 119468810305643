import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Loader } from 'semantic-ui-react';
import useUser from 'util/useUser';

import styles from './PageHeader.module.scss';
import ColoredText from '../ColoredText';

const HeaderBox = ({ children, textAlign = 'left', basis }) => {
    return (
        <div
            style={{ textAlign, flexBasis: `${basis}%` }}
            className={styles.headerBox}
            children={children}
        />
    );
};

const PageHeader = ({ loading, content, rightContent, basis = 0 }) => {
    const user = useUser();
    const location = useLocation();

    if (!user.id) {
        return null;
    }

    let impersonationPrefix = '';
    if (user.impersonation && !user.impersonatingOwnClient) {
        impersonationPrefix = 'Impersonerer: ';
    }

    const slash = (
        <span
            style={{ opacity: 0.50 }}
            children={<>&nbsp;/&nbsp;</>}
        />
    );

    return (
        <div style={{ background: 'white', padding: '10px 0', borderBottom: '1px solid #d3d6db' }}>
            <Container>
                <div style={{ display: 'flex' }}>
                    <HeaderBox basis={100 - basis}>
                        <b>
                            <ColoredText
                                content={`${impersonationPrefix}${user.displayName}`}
                                link={location.pathname === '/' ? undefined : '/'}
                                underlined={false}
                            />
                            {slash}{loading ? <Loader inline active size='tiny' /> : content}
                        </b>
                    </HeaderBox>
                    {
                        rightContent && <HeaderBox textAlign='right' basis={basis}>
                            {rightContent}
                        </HeaderBox>
                    }
                </div>
            </Container>
        </div>
    );
};

export default PageHeader;