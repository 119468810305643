import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Message, Segment } from 'semantic-ui-react';
import { erpIdentifiers } from 'util/erpSystems';
import queryParams, { makeQueryParams } from 'util/QueryParams';

function EconomicIntegration ({ history }) {
    const params = queryParams();

    if (params.token) {
        history.push('/register/erhverv' + makeQueryParams({
            token: params.token,
            referrer: erpIdentifiers.economic,
        }));
        return null;
    }
    
    return <Container>
        <br />
        <Segment>
            <Message
                color='red'
                icon='warning circle'
                header='Der opstod en fejl'
                content='Vi kunne ikke integrerere din E-conomic konto.'
            />
        </Segment>
    </Container>;
}

export default withRouter(EconomicIntegration);