export function formatNumber(num) {
    if (isNaN(num)) {
        return num;
    }
    try {
        num = Math.trunc(num);
        const digits = (num + '').split('').reverse();
        let res = '';
        for (var i = 0; i < digits.length; ++i) {
            res = digits[i] + res;
            if (i % 3 === 2 && i < digits.length - 1) {
                res = "." + res;
            }
        }
        if (res.startsWith('-.')) {
            res = res.replace('-.', '-')
        }
        return res;
    } catch (err) {
        return num;
    }
}