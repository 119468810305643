import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { isAkademi } from 'util/userMethods';
import { useRootUser } from 'util/useUser';
import { ProductPageWithSubHeader } from 'design/molecules/ProductPage';

import PurchaseNotAllowed from './PurchaseNotAllowed';
import CreditsTaxYearUnlocker from './CreditsTaxYearUnlocker';
import PaymentFlowCredits from './PaymentFlowCredits';
import PaymentFlowAccountantCourse from './PaymentFlowAccountantCourse';
import PaymentFlowPackage from './PaymentFlowPackage';
import PaymentFlowUpgradePackage from './PaymentFlowUpgradePackage';
import PaymentFlowStarterHelp from './PaymentFlowStarterHelp';
import PaymentFlowManualHoldingReview from './PaymentFlowManualHoldingReview';
import { shoppingCartEnterd } from '../../../http/hubspot';
import { USE_CUSTOM_DISCOUNT_SYSTEM } from 'flags';

const AccountantPaymentRoutes = () => {
    const { productID, taxYear } = useParams();
    const { pathname } = useLocation();

    if (pathname.startsWith('/betaling/credits')) {
        return <PaymentFlowCredits />;
    }

    if (pathname.startsWith('/betaling/raadgiverkursus')) {
        return <PaymentFlowAccountantCourse />;
    }

    return (
        <ProductPageWithSubHeader subHeader={`Få fuld adgang ∙ Skatteår ${taxYear}`}>
            <CreditsTaxYearUnlocker productID={productID} taxYear={taxYear} />
        </ProductPageWithSubHeader>
    );
};

const RegularUserPaymentRoutes = () => {
    const { productID } = useParams();
    const { pathname } = useLocation();

    if (productID === 'solceller') {
        return (
            <ProductPageWithSubHeader subHeader='Køb ikke tilladt'>
                <PurchaseNotAllowed 
                    header='Køb ikke tilladt'
                    msg='Vi tilbyder desværre ikke længere dette produkt'
                />
            </ProductPageWithSubHeader>
        );
    }

    if (pathname.startsWith('/betaling/opgrader')) {
        return <PaymentFlowUpgradePackage />;
    }

    if (pathname.startsWith('/betaling/opstartshjaelp')) {
        return <PaymentFlowStarterHelp />;
    }

    if (pathname.startsWith('/betaling/manuel-gennemgang') && USE_CUSTOM_DISCOUNT_SYSTEM()) {
        return <PaymentFlowManualHoldingReview />;
    }

    return <PaymentFlowPackage />;
};

const Payment = () => {
    const user = useRootUser();
    const { productID } = useParams();

    useEffect(() => {
        shoppingCartEnterd(user, productID)
            .catch(error => console.error('Failed to process shopping cart entry:', error));
    }, [user, productID]);

    if (isAkademi(user)) {
        return (
            <ProductPageWithSubHeader subHeader='Køb ikke nødvendigt'>
                <PurchaseNotAllowed 
                    header='Køb ikke nødvendigt'
                    msg='Du har allerede adgang til produktet'
                />
            </ProductPageWithSubHeader>
        );
    }    

    if (user.isAccountant()) {
        return <AccountantPaymentRoutes />;
    }

    return <RegularUserPaymentRoutes />;
};

export default Payment;