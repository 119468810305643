import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Header, Loader, Segment } from 'semantic-ui-react';
import { registerToken } from 'http/economic';
import QueryParams from 'util/QueryParams';
import { ECONOMIC_AUTH_LOCATION } from 'config/config';
import { ECONOMIC_TOKEN } from 'config/config';
import ColoredText from 'design/atoms/ColoredText';

const EconomicConnector = ({ onRegister }) => {
    const [connecting, setConnecting] = useState(false);
    const location = useLocation();
    const history = useHistory();

    // look for token in URL upon mount
    useEffect(() => {
        const query = QueryParams(location.search);
		const token = query.token;
        const erp = query.erp;

        // ensure token & erp provided
        if (!token || !erp) {
            return;
        }

        // clear URL
        history.replace({
            search: '',
        });

        // register token
        const doRegister = async () => {
            setConnecting(true);
            await registerToken(token);
            onRegister && onRegister();
        };

        doRegister();
    }, [location, history, onRegister]);

    const getCleanURL = () => {
        const { origin, pathname } = window.location;
        return origin + pathname;
    };

    const gotoEcononmic = () => {
        const economicAuthURL = `${ECONOMIC_AUTH_LOCATION}${ECONOMIC_TOKEN}`;
        const returnURL = `${getCleanURL()}?erp=economic`;
        const link = `${economicAuthURL}&redirectUrl=${encodeURIComponent(returnURL)}`;
        window.location.href = link;
    };

    if (connecting) {
        return (
            <Segment basic textAlign='center'>
                <Loader active inline size='big' />
                <Header>Forbinder</Header>
            </Segment>
        );
    }

    return <div>
        <p>
            Ved klik på nedenstående link, vil du blive sendt til E-conomic,
            hvor du kan give Digital Revisor adgang til at hente dine bogføringsdata.
        </p>
        <ColoredText
            color='green'
            iconPosition='left'
            link
            content='Forbind til Economic'
            icon='sign in'
            onClick={gotoEcononmic}
        />
    </div>;
};

export default EconomicConnector;