import get from 'lodash.get';
import React, { useMemo, useState } from 'react';
import { Button, Icon, Modal, Popup, Transition } from 'semantic-ui-react';
import { documentComponentTypes, blockTypes, documentComponentDataTypes } from '@digital-revisor/node-report-editor-model';
import deepCopyObject from 'util/deepCopyObject';
import EditableIndicator from './EditableIndicator';
import OverridesForm from './OverridesForm';
import createMarkdownFromSection from './createMarkdownFromSection';

const SectionView = ({
    section,
    documentOverrideConfiguration,
    setDocumentOverrideConfiguration,
    blockIdToType,
    working,
}) => {
    const [overrideFormValues, setOverrideFormValues] = useState(null);
    const [uncommitedOverrides, setUncommitedOverrides] = useState({});

    const markdown = useMemo(() => {
        return createMarkdownFromSection(section, documentOverrideConfiguration);
    }, [section, documentOverrideConfiguration]);
    
    const renderDocumentElement = ({ element, ...markdown }) => {
        const {
            tag,
            style,
            children,
            innerText,
            ...other
        } = markdown;

        const props = {
            style,
            ...other,
        };

        let convertedChildren;
        if (innerText) {
            convertedChildren = innerText;
        } else if (Array.isArray(children) && children.length > 0) {
            convertedChildren = children.map(renderDocumentElement);
        }

        const reactElement = React.createElement(tag, props, convertedChildren);

        const editorConfig = element?.editorConfig;
        const isSection = element?.kind === blockTypes.section.id;
        if (!editorConfig || isSection) {
            return reactElement;
        }

        const { id, activeDocumentComponentOverrides, activeBuildingBlockOverrides } = editorConfig;

        const prepareFormValue = (propName, prop, alignment) => {
            const overrideValue = get(documentOverrideConfiguration, [alignment, id, propName].join('.'));

            const value = (
                overrideValue !== undefined
                    ? overrideValue
                    : get(element, ['data', propName], prop?.defaultValue)
            );

            return {
                propName,
                alignment,
                editorID: id,
                text: prop.texts['da-DK'],
                dataType: prop.dataType,
                choices: prop.choices,
                value: value,
            };
        };

        const formValues = [];

        for (const propName of activeDocumentComponentOverrides || []) {
            const prop = documentComponentTypes[element?.kind]?.props[propName];

            if (!prop.overridable) {
                continue;
            }

            formValues.push(prepareFormValue(propName, prop, 'documentComponentOverrides'));
        }

        for (const propName of activeBuildingBlockOverrides || []) {
            // TODO: provide block id + editor id from server
            const blockType = blockIdToType[editorConfig.id.split(':').at(-1)];
            const overrideProperties = blockTypes[blockType]?.overrideProperties || {};
            const prop = overrideProperties[propName];

            formValues.push(prepareFormValue(propName, prop, 'buildingBlockOverrides'));
        }

        if (formValues.length === 0) {
            return reactElement;
        }
        
        // the modal is currently only used when overwriting string related properties
        const useModal = formValues.some(formValue => formValue.dataType === documentComponentDataTypes.string);
        const overwritten = element?.data?.overwritten || activeBuildingBlockOverrides?.some(prop => {
            const buildingBlockOverrides = documentOverrideConfiguration?.buildingBlockOverrides || {};
            const overridesForCurrentBlock = buildingBlockOverrides[editorConfig.id] || {};
            return overridesForCurrentBlock[prop];
        });

        if (useModal) {
            return <EditableIndicator
                children={reactElement}
                hasChanges={overwritten}
                onClick={() => {
                    setOverrideFormValues({ id, formValues, overwritten });
                    setUncommitedOverrides(deepCopyObject(documentOverrideConfiguration));
                }}
            />;
        }

        return (
            <Popup
                on='click'
                position='bottom center'
                content={renderOverridesForm(formValues, documentOverrideConfiguration, setDocumentOverrideConfiguration)}
                trigger={
                    <div>
                        <EditableIndicator
                            children={reactElement}
                            hasChanges={overwritten}
                        />
                    </div>
                }
            />
        );
    };

    const closeEditorOptions = () => {
        setUncommitedOverrides({});
        setOverrideFormValues(null);
    };

    const resetOverrides = async () => {
        const configurationCopy = { ...documentOverrideConfiguration };

        const { buildingBlockOverrides = {}, documentComponentOverrides = {} } = configurationCopy;

        if (buildingBlockOverrides[overrideFormValues.id] !== undefined) {
            delete buildingBlockOverrides[overrideFormValues.id]
        }

        if (documentComponentOverrides[overrideFormValues.id] !== undefined) {
            delete documentComponentOverrides[overrideFormValues.id];
        }

        closeEditorOptions();
        setDocumentOverrideConfiguration(configurationCopy);
    };

    const commitOverrideValues = () => {
        setDocumentOverrideConfiguration(uncommitedOverrides);
        closeEditorOptions();
    };

    const renderOverridesModal = () => {
        if (!overrideFormValues) return null;

        return (
            <Modal open onClose={closeEditorOptions}>
                <Modal.Content>
                    {renderOverridesForm(overrideFormValues.formValues, uncommitedOverrides, setUncommitedOverrides)}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' content='Annullér' onClick={closeEditorOptions} />
                    {overrideFormValues.overwritten && <Button color='black' basic content='Nulstil ændringer' icon='undo' onClick={resetOverrides} />}
                    <Button primary content='OK' onClick={commitOverrideValues} />
                </Modal.Actions>
            </Modal>
        );
    };

    const renderOverridesForm = (formValues, overrides, onChange) => {
        return <OverridesForm
            formValues={formValues}
            documentOverrideConfiguration={overrides}
            onChange={onChange}
        />;
    };

    const renderLoadingOverlay = () => {
        return (
            <Transition visible={working} animation='fade down' duration={1000}>
                <div
                    style={{
                        position: 'absolute',
                        display: 'inline-block',
                        padding: '0.5em',
                        background: 'rgb(240, 240, 240)',
                        borderBottomRightRadius: '4px',
                        boxShadow: '2px 2px 1px 0px lightgray',
                    }}
                    children={<span><Icon name='spinner' loading /> Arbejder...</span>}
                />
            </Transition>
        );
    };

    return (
        <>
            {renderOverridesModal()}
            <div style={{ all: 'initial' }}>
                <div
                    style={{
                        fontFamily: 'Roboto',
                        background: 'white',
                        border: '1px solid lightgray',
                        borderRadius: '4px',
                        position: 'relative',
                    }}
                >
                    {renderLoadingOverlay()}
                    {markdown && renderDocumentElement(markdown)}
                </div>
            </div>
        </>
    );
};

export default SectionView;